import avatColors from "./avatColors";
import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    primary: {
      main: avatColors.red
    },
    secondary: {
      main: avatColors.grey,
      light: avatColors.lightGrey,
      dark: avatColors.darkGrey,
      contrastText: avatColors.veryLightGrey
    }
  }
});

import React, {useEffect, useState} from "react";
import ViewListIcon from "@material-ui/icons/AddCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visible: {
      visibility: "visible"
    },
    hidden: {
      visibility: "hidden"
    },
    menuButton: {
      color: theme.palette.secondary.dark,
      fontWeight: "bold",
      "&:hover": {
        textDecoration: "underline",
        color: theme.palette.primary.main,
        background: theme.palette.secondary.light,
      }
    }
  })
);

interface BeforeInstallpromptEvent extends Event {
  userChoice: Promise<void>;
  prompt(): void;
}

interface IInstallButtonProps {
}

const InstallButton: React.FunctionComponent<IInstallButtonProps> = (props) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const [installPrompt, setInstallPrompt] = useState<Event | null>(null);
  
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", saveInstallPrompt);
    return () => window.removeEventListener("beforeinstallprompt", saveInstallPrompt);
  });
  useEffect(() => {
    window.addEventListener("appinstalled", appInstalled);
    return () => window.removeEventListener("appinstalled", appInstalled);
  });

  const installPwa = () => {
    if (installPrompt !== null) {
      (installPrompt as BeforeInstallpromptEvent).prompt();
      setVisible(false);
      (installPrompt as BeforeInstallpromptEvent).userChoice
        .then((choice: any) => {
          if(choice.outcome === "accepted") {
            console.log("accepted");
          } else {
            console.log("cancelled");
          }
        });
        setInstallPrompt(null);  
    }
  }

  const saveInstallPrompt = (event: Event) => {
    console.log("saveInstallPrompt" + JSON.stringify(event));
    setInstallPrompt(event);
    setVisible(true);
  };

  const appInstalled = (event: Event) => {
    console.log("appInstalled" + JSON.stringify(event));
  };

  return (
    <div className={visible ? classes.visible : classes.hidden}>
      <IconButton onClick={installPwa} className={classes.menuButton}>
        <ViewListIcon/>
      </IconButton>
    </div>
  );

}

export default InstallButton;
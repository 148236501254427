import { useContext } from "react";
import { DeviceType } from "../../../../API";
import { AtaEntry } from "../../../../store/extendedApiTypes";
import { useAta } from "../../../../store/ata/useAta";
import { getMessageText, getMessageType } from "../../../../store/extendedApiUtils";
import LanguageContext from "../../../hoc/LanguageContextProvider";

export interface LocalizedAtaEntry {
  id: string;
  acknowledged: boolean;
  messageType: { original: string; type: string; extension?: string | undefined };
  messageText: string;
  localizedTimestamp: string;
}

export interface UseDeviceAlarmsState {
  loading: boolean;
  error: any;
  ataEntries: LocalizedAtaEntry[];
  localizedAtaTimestamp: string | null;
}

export const useDeviceAlarms = (deviceId: string, deviceType: DeviceType): UseDeviceAlarmsState => {
  const { currentLanguage } = useContext(LanguageContext);
  const { loading, error, ata } = useAta(deviceId, deviceType);

  if (!ata) {
    return { loading, error, ataEntries: [], localizedAtaTimestamp: null };
  }

  const ataEntries: LocalizedAtaEntry[] = [];

  ata.items.forEach((item: AtaEntry) => {
    const messageText = getMessageText(item.message, currentLanguage);
    const messageType = getMessageType(item.messageType);
    const localizedTimestamp = new Date(item.timestamp).toLocaleString(currentLanguage);

    ataEntries.push({
      id: item.id,
      acknowledged: item.acknowledged,
      messageType: messageType,
      messageText: messageText,
      localizedTimestamp: localizedTimestamp,
    });
  });

  const localizedAtaTimestamp = ata.timestamp
    ? new Date(ata.timestamp).toLocaleString(currentLanguage)
    : null;

  return { loading, error, ataEntries, localizedAtaTimestamp };
};

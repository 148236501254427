/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addDatapointsForDevice = /* GraphQL */ `
  mutation AddDatapointsForDevice($input: AddDatapointsInput!) {
    addDatapointsForDevice(input: $input) {
      deviceId
      deviceType
      datapoints {
        id
        analogValue
        discreteValue {
          langCode
          text
        }
        discreteValueAsInt
      }
      timestamp
    }
  }
`;
export const deleteDatapointsForDevice = /* GraphQL */ `
  mutation DeleteDatapointsForDevice($input: DeviceIdAndTypeInput!) {
    deleteDatapointsForDevice(input: $input) {
      deviceId
      deviceType
      datapoints {
        id
        analogValue
        discreteValue {
          langCode
          text
        }
        discreteValueAsInt
      }
      timestamp
    }
  }
`;
export const updateDataModelForDevice = /* GraphQL */ `
  mutation UpdateDataModelForDevice($input: UpdateDataModelInput!) {
    updateDataModelForDevice(input: $input) {
      deviceId
      deviceType
      product
      datapointModels {
        id
        appIdDesc
        type
        unit
        text {
          langCode
          text
        }
        tags
        discreteValues {
          value
          de
          en
        }
      }
    }
  }
`;
export const deleteDataModelForDevice = /* GraphQL */ `
  mutation DeleteDataModelForDevice($input: DeviceIdAndTypeInput!) {
    deleteDataModelForDevice(input: $input) {
      deviceId
      deviceType
      product
      datapointModels {
        id
        appIdDesc
        type
        unit
        text {
          langCode
          text
        }
        tags
        discreteValues {
          value
          de
          en
        }
      }
    }
  }
`;
export const updateAtaForDevice = /* GraphQL */ `
  mutation UpdateAtaForDevice($input: UpdateAtaInput!) {
    updateAtaForDevice(input: $input) {
      deviceId
      deviceType
      items {
        id
        acknowledged
        message {
          langCode
          text
        }
        messageType
        timestamp
      }
      timestamp
    }
  }
`;
export const updateAtaConfigOfUserForDevice = /* GraphQL */ `
  mutation UpdateAtaConfigOfUserForDevice($input: UpdateAtaConfigInput) {
    updateAtaConfigOfUserForDevice(input: $input)
  }
`;
export const deleteAtaForDevice = /* GraphQL */ `
  mutation DeleteAtaForDevice($input: DeviceIdAndTypeInput!) {
    deleteAtaForDevice(input: $input) {
      deviceId
      deviceType
      items {
        id
        acknowledged
        message {
          langCode
          text
        }
        messageType
        timestamp
      }
      timestamp
    }
  }
`;
export const addDeviceForUser = /* GraphQL */ `
  mutation AddDeviceForUser($input: AddDeviceInput!) {
    addDeviceForUser(input: $input) {
      id
      type
      appName
      engine
      plant
      timeZone
      ataNoteType
      dataModel {
        deviceId
        deviceType
        product
        datapointModels {
          id
          appIdDesc
          type
          unit
          text {
            langCode
            text
          }
          tags
          discreteValues {
            value
            de
            en
          }
        }
      }
    }
  }
`;
export const deleteDeviceForUser = /* GraphQL */ `
  mutation DeleteDeviceForUser($input: DeleteDeviceInput!) {
    deleteDeviceForUser(input: $input) {
      id
      type
      appName
      engine
      plant
      timeZone
      ataNoteType
      dataModel {
        deviceId
        deviceType
        product
        datapointModels {
          id
          appIdDesc
          type
          unit
          text {
            langCode
            text
          }
          tags
          discreteValues {
            value
            de
            en
          }
        }
      }
    }
  }
`;
export const addUser = /* GraphQL */ `
  mutation AddUser($input: AddUserInput!) {
    addUser(input: $input) {
      id
      preferedLocale
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
      preferedLocale
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      preferedLocale
    }
  }
`;

import React, { useState, useEffect } from "react";
import { AmplifySignUp, AmplifyAuthenticator } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

import { UserContextProvider } from "../hoc/UserContextProvider";
import { LanguageContextProvider } from "../hoc/LanguageContextProvider";
import { HashRouter } from "react-router-dom";
import AppTheme from "../../config/AppTheme";
import Main from "../main";
import TopAppBar from "../navigation/topAppBar";
import ScrollTop from "../hoc/ScrollTop";
import GlobalStateInitializer from "../hoc/GlobalStateInitializer";

import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import "./App.css";

import IOSPwaPrompt from "../iosPwaPrompt/IOSPwaPrompt";
import I18nLoader from "../auth/I18nLoader";
import setLanguageForAmplifyAuthComponent from "../auth/amplifyAuthComponentConfig";
import { signUpConfig } from "../auth/amplifyAuthSignupConfig";
import { determineCurrentLanguage } from "../auth/i18nUtils";
import { SnackbarProvider } from "notistack";

interface Props {}

const App: React.FC<Props> = (props) => {
  const [authState, setAuthState] = useState<AuthState>();
  const [user, setUser] = useState<object | undefined>();

  useEffect(() => {
    // Current language has to be determined explicitely as the global language context
    // runs after the initalization of the Amplify auth component so that it cannot be
    // used to retrieve the active language.
    const locale = determineCurrentLanguage();
    setLanguageForAmplifyAuthComponent(locale);

    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <LanguageContextProvider>
      <I18nLoader>
        <div className="app">
          <UserContextProvider>
            <GlobalStateInitializer>
              <CssBaseline />
              <HashRouter>
                <MuiThemeProvider theme={AppTheme}>
                  <SnackbarProvider
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    maxSnack={2}
                    hideIconVariant
                  >
                    <Container maxWidth="lg" disableGutters={true}>
                      <div id="back-to-top-anchor">
                        <TopAppBar />
                        <Main />
                        <ScrollTop {...props}>
                          <Fab color="primary" size="small" aria-label="scroll back to top">
                            <KeyboardArrowUpIcon />
                          </Fab>
                        </ScrollTop>
                      </div>
                      <IOSPwaPrompt />
                    </Container>
                  </SnackbarProvider>
                </MuiThemeProvider>
              </HashRouter>
            </GlobalStateInitializer>
          </UserContextProvider>
        </div>
      </I18nLoader>
    </LanguageContextProvider>
  ) : (
    <AmplifyAuthenticator>
      <AmplifySignUp formFields={signUpConfig} slot="sign-up" usernameAlias="email"></AmplifySignUp>
    </AmplifyAuthenticator>
  );
};

export default App;

import * as React from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import deviceMatchParams from "../../routes/deviceMatchParams";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumb: {
      marginTop: -10,
    },
    menuButton: {
      color: theme.palette.secondary.dark,
      textAlign: 'left'
    }
  })
);

const DeviceBreadcrumbs: React.FunctionComponent<deviceMatchParams> = ({ deviceType, deviceId }) => {
  const classes = useStyles();
  
  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}> 
        <Button
          size="small"
          color="inherit"
          variant="text"
          component={Link}
          to="/fleet"
          className={classes.menuButton}
        >
          <Trans> Fleet </Trans>
        </Button>
        <Button
          size="small"
          color="inherit"
          component={Link}
          to={`/fleet/device/${deviceType}/${deviceId}`}
          className={classes.menuButton}
        >
          <Trans> Device </Trans> {deviceId} 
        </Button>
        <Switch>
        <Route
            path="/fleet/device/:deviceType/:deviceId/summary"
            component={() => (
              <Button size="small" color="inherit" className={classes.menuButton}>
                <Trans> Summary </Trans>
              </Button>
            )}
          />
          <Route
            path="/fleet/device/:deviceType/:deviceId/details"
            component={() => (
              <Button size="small" color="inherit" className={classes.menuButton}>
                <Trans> Details </Trans>
              </Button>
            )}
          />
          <Route
            path="/fleet/device/:deviceType/:deviceId/alarms"
            component={() => (
              <Button size="small" color="inherit" className={classes.menuButton}>
                <Trans> Alarms </Trans>
              </Button>
            )}
          />
          <Redirect from="/fleet/device/:deviceType/:deviceId" to="/fleet/device/:deviceType/:deviceId/summary" />
        </Switch>
      </Breadcrumbs>
  );
};

export default DeviceBreadcrumbs;

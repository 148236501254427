import React, { useState, useEffect } from "react";
import { Link, LinkProps, useLocation } from "react-router-dom";
import settingsNavigationStructure from "../settingsNavigationStructure";
import NavigationItem from "../navigationItem";

import Tabs from "@material-ui/core/Tabs";
import { default as Tab, TabProps } from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      marginBottom: 10
    }
  })
);

const LinkTab: React.ComponentType<TabProps & LinkProps> = Tab as React.ComponentType<TabProps & LinkProps>;

interface ISettingsTabNavigationProps {
}

const DeviceTabNavigation: React.FunctionComponent<ISettingsTabNavigationProps> = () => {
  const classes = useStyles();

  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    settingsNavigationStructure.items.forEach((item: NavigationItem) => {
      if (location.pathname === item.url) {
        setTabIndex(item.key);
      }
    });
  }, [location]);

  const handleChange = (event: React.ChangeEvent<{}>, value: number) => {
    setTabIndex(value);
  };

  const linkTabs = settingsNavigationStructure.items.map((item: NavigationItem) => (
    <LinkTab label={<Trans id={item.label}/>} component={Link} to={item.url} key={item.key} />
  ));

  return (
    <Paper className={classes.toolbar}>
      <Tabs value={tabIndex} onChange={handleChange} indicatorColor="primary" textColor="primary">
        {linkTabs}
      </Tabs>
    </Paper>
  );
};

export default DeviceTabNavigation;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserById = /* GraphQL */ `
  query GetUserById {
    getUserById {
      id
      preferedLocale
    }
  }
`;
export const getDevicesByUser = /* GraphQL */ `
  query GetDevicesByUser {
    getDevicesByUser {
      id
      type
      appName
      engine
      plant
      timeZone
      ataNoteType
      dataModel {
        deviceId
        deviceType
        product
        datapointModels {
          id
          appIdDesc
          type
          unit
          text {
            langCode
            text
          }
          tags
          discreteValues {
            value
            de
            en
          }
        }
      }
    }
  }
`;
export const getAtaByDevice = /* GraphQL */ `
  query GetAtaByDevice($deviceId: ID!, $deviceType: DeviceType!) {
    getAtaByDevice(deviceId: $deviceId, deviceType: $deviceType) {
      deviceId
      deviceType
      items {
        id
        acknowledged
        message {
          langCode
          text
        }
        messageType
        timestamp
      }
      timestamp
    }
  }
`;
export const getDatapointsByDevice = /* GraphQL */ `
  query GetDatapointsByDevice($deviceId: ID!, $deviceType: DeviceType!) {
    getDatapointsByDevice(deviceId: $deviceId, deviceType: $deviceType) {
      deviceId
      deviceType
      datapoints {
        id
        analogValue
        discreteValue {
          langCode
          text
        }
        discreteValueAsInt
      }
      timestamp
    }
  }
`;

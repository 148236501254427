import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    menuButton: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      color: theme.palette.secondary.dark,
      fontWeight: "bold",
      "&:hover": {
        textDecoration: "underline",
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.contrastText,
      },
      "&:active": {
        textDecoration: "underline",
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.contrastText,
      }
    },
    appBar: {
      background: theme.palette.secondary.contrastText,
      borderBottomColor: theme.palette.primary.main,
      borderBottomWidth: 3,
      borderBottomStyle: "solid",
      padding: theme.spacing(0)

    },
    urlMatch: {
      textDecorationThickness: "2.5px",
      textDecorationColor: theme.palette.primary.main,
      textDecoration: "underline"
    }
  })
);

import React, { useContext, useEffect } from "react";
import { I18nProvider } from "@lingui/react";
import LanguageContext from "../hoc/LanguageContextProvider";
import { i18n } from "@lingui/core";
import { en, de } from "make-plural/plurals";

i18n.loadLocaleData({ en: { plurals: en }, de: { plurals: de } });

interface Props {
  children: any;
}

async function loadMessages(language: string) {
  return await import(`../../locales/${language}/messages.js`);
}

const I18nLoader: React.FC<Props> = ({ children }) => {
  const { currentLanguage } = useContext(LanguageContext);

  useEffect(() => {
    const loadCatalog = async (lang: string): Promise<void> => {
      const { messages } = await loadMessages(lang);
      i18n.load(lang, messages);
      i18n.activate(lang);
    };

    loadCatalog(currentLanguage);
  }, [currentLanguage]);

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};

export default I18nLoader;

import { DevicesState } from '../../store/globalStoreTypes';
import { t } from '@lingui/macro';

const getStatusMessage = (devicesState: DevicesState): string | null => {
    switch(devicesState) {
        case DevicesState.NOT_INITIALIZED:
            return t`No information available.`;
        case DevicesState.LOADING:
            return t`Loading...`;
        case DevicesState.INITIALIZED:
        case DevicesState.UPDATED:
            return null;
        case DevicesState.NO_DEVICES_FOR_USER:
            return t`No devices available for current user.`;
        case DevicesState.ERROR:
            return t`An error occured.`;
        default:
            throw new Error();
    }
};

export {
    getStatusMessage
};
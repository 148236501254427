import React from "react";
import globalHook from "use-global-hook";

import * as actions from "./globalActions";
import { GlobalState, GlobalActions, DevicesState } from "./globalStoreTypes";
import { FALLBACK_LANGUAGE } from "../components/auth/i18nUtils";

const initialState: GlobalState = {
  devices: [],
  devicesState: DevicesState.NOT_INITIALIZED,
  userData: { preferedLocale: FALLBACK_LANGUAGE },
};

const useGlobal = globalHook<GlobalState, GlobalActions>(React, initialState, actions);

export default useGlobal;

import React from "react";
import { Device } from "../../../../../store/extendedApiTypes";
import { DetailValue, useDeviceDetails } from "../../useDeviceData";
import { Grid } from "@material-ui/core";
import DeviceDataSection from "../../commonComponents/deviceDataSection/DeviceDataSection";
import {
  getTagTranslationsForProduct,
  getSectionOrderForProduct,
} from "../../../../../store/tagTranslations";

// const ROOT_SCOPE: string = "DetailsPage";

export interface IDeviceDetailsProps {
  device: Device;
}

const SEOptimizerDeviceDetails: React.FunctionComponent<IDeviceDetailsProps> = ({ device }) => {
  const { getLiveDetailsLoadingStatus } = useDeviceDetails(device);
  const liveDetailsLoadingStatus: string | undefined = getLiveDetailsLoadingStatus();

  const createGridSectionsByGroup = (liveDetailsLoadingStatus: string | undefined): JSX.Element => {
    interface JsxElementWithSortingIndex {
      element: JSX.Element;
      index: number;
      numOfRows: number;
    }

    const unorderedSections = new Array<JsxElementWithSortingIndex>();
    // const titlesWithLiveValues: Map<string, DetailValue[]> = getLiveValuesForScope(ROOT_SCOPE, 0);
    const titlesWithLiveValues: Map<string, DetailValue[]> = new Map([
      [
        "KPIs_Operation",
        [
          { type: 1, description: "Cloudverfügbarkeit", value: "100 %" },
          { type: 1, description: "Routerverfügbarkeit", value: "100 %" },
          { type: 1, description: "Virtual Service Verfügbarkeit", value: "100 %" },
          { type: 1, description: "RRMSE_Strompreisprognose", value: "15,5 %" },
          { type: 1, description: "MAPE_Strompreisprognose", value: "10,5 %" },
          { type: 1, description: "Wetterdaten geholt", value: "ja" },
          { type: 1, description: "Istwert gesendet", value: "ja" },
          { type: 1, description: "Sollwert empfangen", value: "ja" },
          { type: 1, description: "Prognose Wetterdaten geholt", value: "ja" },
          { type: 1, description: "Istwert gesendet", value: "ja" },
          { type: 1, description: "Sollwert empfangen", value: "ja" },
          { type: 1, description: "Historische Wetterdaten geholt", value: "ja" },
        ],
      ],
      [
        "KPIs_Components",
        [
          {
            type: 1,
            description: "Plantreue bezüglich Energie",
            value: "95,75 %",
          },
          {
            type: 1,
            description: "Startzähler",
            value: "2",
          },
          {
            type: 1,
            description: "Betriebsstunden",
            value: "60 h",
          },
          {
            type: 1,
            description: "Volllaststunden",
            value: "60 h",
          },
          {
            type: 1,
            description: "Maschinenverfügbarkeit",
            value: "75,5 %",
          },
          {
            type: 1,
            description: "Mehrerlös der Anlage",
            value: "257,5 €",
          },
        ],
      ],
      [
        "KPIs_Malos",
        [
          { type: 1, description: "Warenverbrauch", value: "55752,4 kWh" },
          { type: 1, description: "Max. Warenverbrauch", value: "1589,3 kw" },
          { type: 1, description: "Eingespeiste elekt. Energie", value: "19125,3 kWh" },
          { type: 1, description: "Max. eingespeise elekt. Energie", value: "477,9 kW" },
        ],
      ],
    ]);

    const SECTION_ORDER = getSectionOrderForProduct(device.type, device.appName);

    titlesWithLiveValues.forEach((values: DetailValue[], title: string) => {
      const translatedTitle = getTagTranslationsForProduct(device.type, device.appName).get(title);
      unorderedSections.push({
        element: (
          <Grid item xs={12} sm={12} lg={12} key={SECTION_ORDER.indexOf(title)}>
            <DeviceDataSection values={values} sectionHeader={translatedTitle}></DeviceDataSection>
          </Grid>
        ),
        index: SECTION_ORDER.indexOf(title),
        numOfRows: values.length,
      });
    });

    const sortedSections = unorderedSections.sort(
      (a: JsxElementWithSortingIndex, b: JsxElementWithSortingIndex) => a.index - b.index
    );

    let firstColumnSections = new Array<JSX.Element>();
    const secondColumnSections = new Array<JSX.Element>();

    if (sortedSections.length <= 1) {
      firstColumnSections = sortedSections.map((item) => item.element);
    } else {
      const totalRows = sortedSections
        .map((item) => item.numOfRows)
        .reduce((accumulator, currentValue) => accumulator + currentValue);

      let counter = 0;
      sortedSections.forEach((item: JsxElementWithSortingIndex) => {
        counter += item.numOfRows;
        if (item.index === 0 || counter <= Math.ceil(totalRows / 2)) {
          firstColumnSections.push(item.element);
        } else {
          secondColumnSections.push(item.element);
        }
      });
    }

    return (
      <Grid container spacing={3}>
        <Grid container item xs={12} sm={6} lg={6} style={{ alignContent: "baseline" }}>
          {firstColumnSections}
        </Grid>
        <Grid container item xs={12} sm={6} lg={6} style={{ alignContent: "baseline" }}>
          {secondColumnSections}
        </Grid>
      </Grid>
    );
  };

  const orderedLiveSections: JSX.Element = createGridSectionsByGroup(liveDetailsLoadingStatus);

  return <div>{orderedLiveSections}</div>;
};

export default SEOptimizerDeviceDetails;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateAtaForDevice = /* GraphQL */ `
  subscription OnUpdateAtaForDevice($deviceId: ID!, $deviceType: DeviceType!) {
    onUpdateAtaForDevice(deviceId: $deviceId, deviceType: $deviceType) {
      deviceId
      deviceType
      items {
        id
        acknowledged
        message {
          langCode
          text
        }
        messageType
        timestamp
      }
      timestamp
    }
  }
`;
export const onAddDatapointsForDevice = /* GraphQL */ `
  subscription OnAddDatapointsForDevice($deviceId: ID!, $deviceType: DeviceType!) {
    onAddDatapointsForDevice(deviceId: $deviceId, deviceType: $deviceType) {
      deviceId
      deviceType
      datapoints {
        id
        analogValue
        discreteValue {
          langCode
          text
        }
        discreteValueAsInt
      }
      timestamp
    }
  }
`;

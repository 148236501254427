import { DatapointType } from "../../API";
import { useDatapoints } from "./useDatapoints";
import { Device, DatapointModel, Datapoint } from "../extendedApiTypes";
import { EnrichedDatapoints, EnrichedDatapoint } from "./customDatapointTypes";

interface UseEnrichedDatapointsState {
  loading: boolean;
  error: any;
  enrichedDatapoints: EnrichedDatapoints | null;
}

export const useEnrichedDatapoints = (device: Device): UseEnrichedDatapointsState => {
  const { loading, error, datapoints } = useDatapoints(device.id, device.type);
  if (!datapoints) {
    return { loading, error, enrichedDatapoints: null };
  }

  if (!device.dataModel || device.dataModel.datapointModels.length === 0) {
    return { loading: false, error: "", enrichedDatapoints: null };
  }

  const enrichedDatapointsList: EnrichedDatapoint[] = [];
  datapoints.datapoints.forEach((datapoint: Datapoint) => {
    const datapointModel: DatapointModel | undefined = device.dataModel?.datapointModels.find(
      (datapointModel: DatapointModel) => datapointModel.id === datapoint.id
    );

    if (
      datapointModel &&
      datapointModel.type === DatapointType.ANALOG_MEASURAND &&
      datapoint.analogValue !== null
    ) {
      enrichedDatapointsList.push({
        id: datapointModel.id,
        appIdDesc: datapointModel.appIdDesc,
        text: datapointModel.text,
        unit: datapointModel.unit,
        analogValue: datapoint.analogValue,
      });
    }

    if (
      datapointModel &&
      datapointModel.type === DatapointType.DISCRETE_MEASURAND &&
      datapoint.discreteValue
    ) {
      enrichedDatapointsList.push({
        id: datapointModel.id,
        appIdDesc: datapointModel.appIdDesc,
        text: datapointModel.text,
        discreteValue: datapoint.discreteValue,
      });
    }

    if (!datapointModel) {
      // console.log("No datapoint model for datapoint " + datapoint.id + "!"); // Should not happen
    }
  });

  const enrichedDatapoints = {
    deviceId: device.id,
    deviceType: device.type,
    product: device.dataModel.product,
    enrichedDatapoints: enrichedDatapointsList,
    timestamp: datapoints.timestamp,
  };

  return { loading, error, enrichedDatapoints };
};

import React from "react";
import useGlobal from "../../../store";

import { Typography, Grid } from "@material-ui/core";
import { getStatusMessage } from "../routeUtils";

import { Trans } from "@lingui/macro";
import GeneralSettingsSection from "./GeneraSettingsSection";
import DeviceSettingsSection from "./DeviceSettingsSection";
import { useLingui } from "@lingui/react";

interface ISettingsAlarmsProps {}

const SettingsAlarms: React.FunctionComponent<ISettingsAlarmsProps> = () => {
  const [{ devicesState }] = useGlobal();
  const { i18n } = useLingui();
  const statusMessage = getStatusMessage(devicesState);

  return (
    <div className={"ataSettingsTable"}>
      {statusMessage && <Typography variant="body2">{<Trans id={statusMessage} />}</Typography>}
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
        <GeneralSettingsSection i18n={i18n} />
        <DeviceSettingsSection i18n={i18n} />
      </Grid>
    </div>
  );
};

export default SettingsAlarms;

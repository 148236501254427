import React, { ChangeEvent, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import settingsNavigationStructure from "../settingsNavigationStructure";
import NavigationItem from "../navigationItem";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: -16,
      position: "fixed",
      bottom: 0,
      width: "100%",
      backgroundColor: theme.palette.secondary.light
    }
  })
);

interface ISettingsBottomNavigationProps {
}

const SettingsBottomNavigation: React.FunctionComponent<ISettingsBottomNavigationProps> = () => {
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const location = useLocation();

  useEffect(() => {
    settingsNavigationStructure.items.forEach((item: NavigationItem) => {
      if (location.pathname === item.url) {
        setValue(item.key);
      }
    });
  }, [location]);

  const updateValue = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const actions = settingsNavigationStructure.items.map((item: NavigationItem) => (
    <BottomNavigationAction component={Link} to={item.url} icon={<item.icon />} key={item.key} />
  ));

  return (
    <BottomNavigation className={classes.root} value={value} onChange={updateValue} showLabels>
      {actions}
    </BottomNavigation>
  );
};

export default SettingsBottomNavigation;

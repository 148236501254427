import React from "react";
import { getAtaNotificationTypeName, Device } from "../../../../../store/extendedApiTypes";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Grid,
  Paper,
} from "@material-ui/core";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      marginTop: 25,
    },
  })
);

export interface IDeviceDetailsProps {
  device: Device;
}

const DefaultDeviceDetails: React.FunctionComponent<IDeviceDetailsProps> = ({ device }) => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6} lg={4} key="2">
          <Typography variant="body1" paragraph>
            <Trans>Device details</Trans>:
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="Static device properties" size="small">
              <TableBody>
                <TableRow key="1">
                  <TableCell component="th" scope="row">
                    Serial number
                  </TableCell>
                  <TableCell align="right">{device.id}</TableCell>
                </TableRow>
                <TableRow key="2">
                  <TableCell component="th" scope="row">
                    Device type
                  </TableCell>
                  <TableCell align="right">{device.type}</TableCell>
                </TableRow>
                {device && device.appName && (
                  <TableRow key="3">
                    <TableCell component="th" scope="row">
                      Application
                    </TableCell>
                    <TableCell align="right">{device.appName}</TableCell>
                  </TableRow>
                )}
                {device && device.engine && (
                  <TableRow key="4">
                    <TableCell component="th" scope="row">
                      Engine
                    </TableCell>
                    <TableCell align="right">{device.engine}</TableCell>
                  </TableRow>
                )}
                {device && device.plant && (
                  <TableRow key="5">
                    <TableCell component="th" scope="row">
                      Plant
                    </TableCell>
                    <TableCell align="right">{device.plant}</TableCell>
                  </TableRow>
                )}
                {device && device.timeZone && (
                  <TableRow key="6">
                    <TableCell component="th" scope="row">
                      Time zone
                    </TableCell>
                    <TableCell align="right">{device.timeZone}</TableCell>
                  </TableRow>
                )}
                {device && device.ataNoteType && (
                  <TableRow key="7">
                    <TableCell component="th" scope="row">
                      Notification type
                    </TableCell>
                    <TableCell align="right">
                      {getAtaNotificationTypeName(device.ataNoteType)}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default DefaultDeviceDetails;

import React, { useState, useEffect } from "react";
import { LOCAL_STORAGE_KEY, FALLBACK_LANGUAGE, determineCurrentLanguage } from "../auth/i18nUtils";

interface ILanguageContextValue {
  currentLanguage: string;
  setCurrentLanguage: (language: string) => void;
}

interface IContextProviderProps {
  children: any; // TODO
}

const LanguageContext = React.createContext<ILanguageContextValue>({
  currentLanguage: FALLBACK_LANGUAGE,
  setCurrentLanguage: () => {},
});

export const LanguageContextProvider: React.FunctionComponent<IContextProviderProps> = ({
  children,
}) => {
  const [currentLanguage, setCurrentLanguage] = useState(determineCurrentLanguage());

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, currentLanguage);
  }, [currentLanguage]);

  return (
    <LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const Consumer = LanguageContext.Consumer;
export default LanguageContext;

import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useDeviceAlarms, LocalizedAtaEntry } from "./useDeviceAlarms";

import { makeStyles } from "@material-ui/core/styles";
import { Hidden, Typography } from "@material-ui/core";
import MaterialTable from "material-table";
import MaterialTableIcons from "../../../hoc/MaterialTableIcons";

import alarmIcon from "../../../../images/warning_modavat_24_enabled.svg";
import failureIcon from "../../../../images/failure_modavat_24_enabled.svg";
import deviceMatchParams from "../../deviceMatchParams";
import "./materialTableOverwrite.css";

import { Trans, t } from "@lingui/macro";
import { getMaterialUiTableLocalizationDefs } from "../../../auth/i18nUtils";
import { useLingui } from "@lingui/react";

const useStyles = makeStyles({
  acknowledged: {
    color: "green",
  },
  notAcknowledged: {
    color: "red",
  },
  ataEntryIcon: {
    float: "left",
    marginRight: "15px",
  },
  ataType: {
    float: "left",
    marginRight: "5px",
    width: "100px",
  },
  dateRight: {
    textAlign: "right",
  },
  // TODO: adapt column widths, once https://github.com/mbrn/material-table/pull/1659 (or any of the duplicates) is merged
});

const exampleAtaEntry: LocalizedAtaEntry = {
  // implement AtaEntry interface in order to access its fields (see below)
  id: "someId",
  acknowledged: true,
  messageType: { original: "ALARM", type: t`ALARM` },
  messageText: "Dummy Alarm",
  localizedTimestamp: "14/04/2020, 21:56:14",
};

interface IDeviceAlarmsProps extends RouteComponentProps<deviceMatchParams> {}

const DeviceAlarms: React.FunctionComponent<IDeviceAlarmsProps> = ({ match }) => {
  const classes = useStyles();
  const { i18n } = useLingui();

  const { loading, error, ataEntries, localizedAtaTimestamp } = useDeviceAlarms(
    match.params.deviceId,
    match.params.deviceType
  );

  return (
    <div className={"deviceAlarmsMaterialTableOverwrite deviceAlarmsTableContainer"}>
      {!(ataEntries.length === 0) && (
        <Hidden xsDown>
          <MaterialTable
            title=""
            columns={[
              {
                title: <Trans>Event type and time</Trans>,
                field: Object.keys(exampleAtaEntry)[4],
                editable: "never",
                render: createDateWithIconRenderer,
              },
              {
                title: <Trans>Message</Trans>,
                field: Object.keys(exampleAtaEntry)[3],
                editable: "never",
                render: createAlarmTextRenderer,
              },
            ]}
            data={ataEntries}
            icons={MaterialTableIcons}
            options={{
              pageSize: 10,
              padding: "dense",
              search: true,
              toolbar: true,
              headerStyle: {
                fontWeight: "bold",
              },
            }}
            localization={getMaterialUiTableLocalizationDefs(i18n)}
          />
        </Hidden>
      )}
      {!(ataEntries.length === 0) && (
        <Hidden smUp>
          <div className={"smallerPadding"}>
            <MaterialTable
              title={""}
              columns={[
                {
                  field: Object.keys(exampleAtaEntry)[4],
                  editable: "never",
                  render: createOneLineRenderer,
                },
              ]}
              data={ataEntries}
              icons={MaterialTableIcons}
              options={{
                pageSize: 10,
                padding: "dense",
                header: false,
                search: false,
                toolbar: false,
              }}
            />
          </div>
        </Hidden>
      )}
      {loading && <Typography variant="body2">Loading...</Typography>}
      {error && <Typography variant="body2">An error occured!</Typography>}
      {!loading && !error && !localizedAtaTimestamp && ataEntries.length === 0 && (
        <Typography variant="body2">
          <Trans>No information available</Trans>.
        </Typography>
      )}
      {!loading && !error && localizedAtaTimestamp && ataEntries.length === 0 && (
        <Typography variant="body2">
          <Trans>No alarms for device.</Trans>
        </Typography>
      )}
    </div>
  );

  // renders the alarm text only
  function createAlarmTextRenderer(rowData: LocalizedAtaEntry): JSX.Element {
    return (
      <div>
        <p>{rowData.messageText}</p>
      </div>
    );
  }

  // renders the alarm icon only
  function createAlarmIconRenderer(rowData: LocalizedAtaEntry): JSX.Element {
    const imageData = getImage(rowData.messageType.original);
    return (
      <div>
        <div className={classes.ataEntryIcon}>
          <img src={imageData.icon} alt={imageData.alt} />
        </div>
        <div className={classes.ataType}>
          <Trans id={rowData.messageType.type} />
          {rowData.messageType.extension}
        </div>
      </div>
    );
  }

  // renders the alarm date
  function createDateRenderer(rowData: LocalizedAtaEntry): JSX.Element {
    return (
      <div>
        <p> {rowData.localizedTimestamp} </p>
      </div>
    );
  }

  // renders the alarm icon with date date; aligns the date to the right in small mode
  function createDateWithIconRenderer(rowData: LocalizedAtaEntry): JSX.Element {
    return (
      <div>
        {createAlarmIconRenderer(rowData)}
        <Hidden xsDown>{createDateRenderer(rowData)}</Hidden>
        <Hidden smUp>
          <div className={classes.dateRight}>{createDateRenderer(rowData)}</div>
        </Hidden>
      </div>
    );
  }

  // renders the alarm icon with date date
  function createOneLineRenderer(rowData: LocalizedAtaEntry): JSX.Element {
    return (
      <div>
        {createDateWithIconRenderer(rowData)}
        {createAlarmTextRenderer(rowData)}
      </div>
    );
  }

  function getImage(messageType: string): { icon: string; alt: string } {
    let icon: string;
    let alt: string;

    if (messageType.includes("ALARM")) {
      icon = alarmIcon;
      alt = "Alarm";
    } else if (messageType.includes("FAILURE")) {
      icon = failureIcon;
      alt = "Failure";
    } else {
      throw new Error("Encountered unknown alarm entry type : " + messageType);
    }
    return {
      icon: icon,
      alt: alt,
    };
  }
};

export default DeviceAlarms;

import { Store } from "use-global-hook";
import { GlobalActions, GlobalState, DevicesState } from "../globalStoreTypes";
import { updateAtaConfigOfUserForDevice } from "../../graphql/mutations";
import { UpdateAtaConfigOfUserForDeviceMutation, UpdateAtaConfigInput } from "../../API";
import { graphqlOperation, API } from "aws-amplify";
import { Device } from "../extendedApiTypes";

const updateAtaConfig = async (
  store: Store<GlobalState, GlobalActions>,
  updateInput: UpdateAtaConfigInput
): Promise<void> => {
  const deviceIndex = store.state.devices.findIndex(
    (device: Device) => device.id === updateInput.deviceId
  );
  if (deviceIndex === -1) {
    console.log(
      `There is no device ${updateInput.deviceId} for the current user!`
    );
    return;
  }

  const device = store.state.devices[deviceIndex];
  let modifiedAtaNoteType = device.ataNoteType;

  try {
    const { data } = (await API.graphql(
      graphqlOperation(updateAtaConfigOfUserForDevice, { input: updateInput })
    )) as {
      data: UpdateAtaConfigOfUserForDeviceMutation;
    };

    modifiedAtaNoteType = data.updateAtaConfigOfUserForDevice;
    console.log(`Changed alarm notification type for device ${updateInput.deviceId} to ${modifiedAtaNoteType}!`)
  } catch (error) {
    console.log(
      `It was not possible to update alarm notification type of device ${updateInput.deviceId}:`,
      error
    );
  }

  // Call set state even if no changes have been made to have a consistent GUI state.
  const modifiedDevices = [...store.state.devices];
  modifiedDevices[deviceIndex] = { ...device, ataNoteType: modifiedAtaNoteType };
  store.setState({ ...store.state, devices: modifiedDevices, devicesState: DevicesState.UPDATED });
};

export default updateAtaConfig;

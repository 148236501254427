import { useContext } from "react";
import { useDatapointsForDisplay } from "../../../store/datapoints/useDatapointsForDisplay";
import { Device } from "../../../store/extendedApiTypes";
import { getMessageTextOrUndefined } from "../../../store/extendedApiUtils";
import LanguageContext from "../../hoc/LanguageContextProvider";

export enum OperationMode {
  MANUAL = "Manual",
  AUTO = "Auto",
  UNKNOWN = "UNKNOWN",
}

enum OpenECSDatapointId {
  NumberOfFailures = "OPENECS-1", // Failure count
  NumberOfAlarms = "OPENECS-2", // Alarm count
  OperationMode = "OPENECS-3",
  PowerInKW = "OPENECS-4", // Actual load KW
  HoursOfLoadRun = "OPENECS-5", // Operation hours
  PowerInPercent = "OPENECS-6", // Actual load
  NumberOfStarts = "OPENECS-7", // No start attempts
  HoursUntilNextMaintenance = "OPENECS-8", // Time to next oil change
  OperationState = "OPENECS-9",
}

export interface OpenECSDeviceCardValues {
  PowerInPercent: number | undefined;
  PowerInKW: number | undefined;
  OperationMode: OperationMode | undefined;
  OperationState: string | undefined;
  HoursOfLoadRun: number | undefined;
  NumberOfStarts: number | undefined;
  HoursUntilNextMaintenance: number | undefined;
  NumberOfFailures: number | undefined;
  NumberOfAlarms: number | undefined;
  valuesTimestamp: string | undefined;
}

export interface UseOpenECSDeviceCardValuesState {
  loading: boolean;
  error: any;
  deviceCardValues: OpenECSDeviceCardValues | null;
}

export const useOpenECSDeviceCardValues = (device: Device): UseOpenECSDeviceCardValuesState => {
  const { currentLanguage } = useContext(LanguageContext);

  const {
    loading,
    error,
    enrichedDatapoints,
    analogDatapointsById,
    discreteDatapointsById,
  } = useDatapointsForDisplay(device);

  if (!enrichedDatapoints) {
    return { loading, error, deviceCardValues: null };
  }

  const determineOperationMode = (locale: string): OperationMode | undefined => {
    const discreteValue = discreteDatapointsById.get(OpenECSDatapointId.OperationMode)
      ?.discreteValue;
    const discreteValueText = getMessageTextOrUndefined(discreteValue, locale);
    if (discreteValueText) {
      // English text of discrete value should not depend on product, because different product use the same enumeration
      if (discreteValueText === "local" || discreteValueText === "Hand") {
        return OperationMode.MANUAL;
      } else if (discreteValueText === "remote" || discreteValueText === "Fern") {
        return OperationMode.AUTO;
      } else {
        console.log(`Unknown operation mode '${discreteValueText}' for device ${device.id}.`);
        return OperationMode.AUTO;
      }
    }
    return undefined;
  };

  const deviceCardValues: OpenECSDeviceCardValues = { 
    PowerInPercent: analogDatapointsById.get(OpenECSDatapointId.PowerInPercent)?.analogValue,
    PowerInKW: analogDatapointsById.get(OpenECSDatapointId.PowerInKW)?.analogValue,
    OperationMode: determineOperationMode(currentLanguage),
    OperationState: getMessageTextOrUndefined(
      discreteDatapointsById.get(OpenECSDatapointId.OperationState)?.discreteValue,
      currentLanguage
    ),
    HoursOfLoadRun: analogDatapointsById.get(OpenECSDatapointId.HoursOfLoadRun)?.analogValue,
    NumberOfStarts: analogDatapointsById.get(OpenECSDatapointId.NumberOfStarts)?.analogValue,
    HoursUntilNextMaintenance: analogDatapointsById.get(
      OpenECSDatapointId.HoursUntilNextMaintenance
    )?.analogValue,
    NumberOfFailures: analogDatapointsById.get(OpenECSDatapointId.NumberOfFailures)?.analogValue,
    NumberOfAlarms: analogDatapointsById.get(OpenECSDatapointId.NumberOfAlarms)?.analogValue,
    valuesTimestamp: enrichedDatapoints.timestamp,
  };

  return { loading, error, deviceCardValues };
};

import React from "react";
import { RouteComponentProps } from "react-router-dom";
import useGlobal from "../../../../store";
import { Device } from "../../../../store/extendedApiTypes";
import deviceMatchParams from "../../deviceMatchParams";
import { DeviceType } from "../../../../API";
import CustomDeviceDetails from "./customDeviceDetails";
import DefaultDeviceDetails from "./defaultDeviceDetails";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import SEOptimizerDeviceDetails from "./seOptimizerDeviceDetails/SEOptimizerDeviceDetails";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      marginTop: 25,
    },
  })
);

interface IDeviceDetailsProps extends RouteComponentProps<deviceMatchParams> {}

const DeviceDetails: React.FunctionComponent<IDeviceDetailsProps> = ({ match }) => {
  const classes = useStyles();

  const deviceId = match.params.deviceId;
  const deviceType = match.params.deviceType;

  const [{ devices }] = useGlobal();
  const device: Device | undefined = devices.find(
    (currentDevice) => currentDevice.id === deviceId && currentDevice.type === deviceType
  );

  if (!device) {
    return (
      <div className={classes.content}>
        <Typography variant="body1" paragraph>
          No details found for device.
        </Typography>
      </div>
    );
  }

  switch (device.type) {
    case DeviceType.SEVA:
    case DeviceType.TEM:
    case DeviceType.OPENECS:
      return <CustomDeviceDetails device={device} />;
    case DeviceType.SE2OPTIMIZER:
      return <SEOptimizerDeviceDetails device={device} />;
    case DeviceType.TESTDUMMY:
      return <DefaultDeviceDetails device={device} />;
  }
};

export default DeviceDetails;

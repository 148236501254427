import { useReducer, useEffect } from "react";
import { getDatapointsByDevice } from "../../graphql/queries";
import { onAddDatapointsForDevice } from "../../graphql/subscriptions";
import {
  GetDatapointsByDeviceQuery,
  DeviceType,
  OnAddDatapointsForDeviceSubscription,
} from "../../API";
import { graphqlOperation, API } from "aws-amplify";
import Observable from "zen-observable";
import { Datapoints } from "../extendedApiTypes";

export interface UseDatapointsState {
  loading: boolean;
  error: any;
  datapoints: Datapoints | null;
}

type Action =
  | { type: "initialSet"; data: GetDatapointsByDeviceQuery }
  | { type: "refresh"; data: OnAddDatapointsForDeviceSubscription }
  | { type: "error"; error: any };

const initialState: UseDatapointsState = { loading: true, error: "", datapoints: null };

const datapointsReducer = (state: UseDatapointsState, action: Action) : UseDatapointsState => {
  switch (action.type) {
    case "initialSet":
      return { loading: false, error: "", datapoints: getDatapointsFromQuery(action.data) };
    case "refresh":
      return { loading: false, error: "", datapoints: getDatapointsFromSubscription(action.data) };
    case "error":
      return { loading: false, error: action.error, datapoints: null };
    default:
      throw new Error();
  }
};

const getDatapointsFromQuery = (data: GetDatapointsByDeviceQuery): Datapoints | null => {
  if (
    data.getDatapointsByDevice !== null &&
    data.getDatapointsByDevice.datapoints !== null &&
    data.getDatapointsByDevice.datapoints.length > 0
  ) {
    return data.getDatapointsByDevice;
  } else {
    return null;
  }
};

const getDatapointsFromSubscription = (
  data: OnAddDatapointsForDeviceSubscription
): Datapoints | null => {
  if (
    data.onAddDatapointsForDevice !== null &&
    data.onAddDatapointsForDevice.datapoints !== null &&
    data.onAddDatapointsForDevice.datapoints.length > 0
  ) {
    return data.onAddDatapointsForDevice;
  } else {
    return null;
  }
};

export const useDatapoints = (deviceId: string, deviceType: DeviceType): UseDatapointsState => {
  const [state, dispatch] = useReducer(datapointsReducer, initialState);

  useEffect(() => {
    const fetchDatapoints = async () => {
      try {
        const { data } = (await API.graphql(
          graphqlOperation(getDatapointsByDevice, {
            deviceId: deviceId,
            deviceType: deviceType,
          })
        )) as {
          data: GetDatapointsByDeviceQuery;
        };

        dispatch({ type: "initialSet", data: data });
      } catch (error) {
        console.log(error);
        dispatch({ type: "error", error: error });
      }
    };

    fetchDatapoints();
  }, [deviceId, deviceType]);

  useEffect(() => {
    let unsubscribe;
    const subscription = API.graphql(
      graphqlOperation(onAddDatapointsForDevice, {
        deviceId: deviceId,
        deviceType: deviceType,
      })
    );
    if (subscription instanceof Observable) {
      const sub = subscription.subscribe({
        next: (payload) => {
          const {
            value: { data },
          }: {
            value: { data: OnAddDatapointsForDeviceSubscription };
          } = payload;

          dispatch({ type: "refresh", data: data });
        },
      });
      unsubscribe = () => {
        sub.unsubscribe();
      };
    }

    return unsubscribe;
  }, [deviceId, deviceType]);

  return state;
};

import { useDatapointsForDisplay } from "./../../../store/datapoints/useDatapointsForDisplay";
import { Device } from "../../../store/extendedApiTypes";
import { getMessageTextOrUndefined } from "../../../store/extendedApiUtils";
import LanguageContext from "../../hoc/LanguageContextProvider";
import { useContext } from "react";

export enum OperationMode {
  MANUAL = "Manual",
  AUTO = "Auto",
  UNKNOWN = "UNKNOWN",
}

export interface SEVADeviceCardValues {
  PowerInKW: number | undefined;
  OperationMode: OperationMode | undefined;
  HoursOfLoadRun: number | undefined;
  HoursUntilNextMaintenance: number | undefined;
  FailuresAvailable: boolean | undefined;
  AlarmsAvailable: boolean | undefined;
  ValuesTimestamp: string | undefined;
  OperationState: string | undefined;
}

export interface UseSEVADeviceCardValuesState {
  loading: boolean;
  error: any;
  deviceCardValues: SEVADeviceCardValues | null;
}

enum SEVADatapointId {
  FailuresAvailable = "SEVA-1#2", // Failures available
  AlarmsAvailable = "SEVA-1#1", // Alarm available
  OperationMode = "SEVA-1#4",
  PowerInKW = "SEVA-2", // Actual load KW
  HoursOfLoadRun = "SEVA-5", // Operation hours
  HoursUntilNextMaintenance = "SEVA-6", // Time to next oil change
  OperationState = "SEVA-1#0",
}

export const useSEVADeviceCardValues = (device: Device): UseSEVADeviceCardValuesState => {
  const { currentLanguage } = useContext(LanguageContext);

  const {
    loading,
    error,
    enrichedDatapoints,
    analogDatapointsById,
    discreteDatapointsById,
  } = useDatapointsForDisplay(device);

  if (!enrichedDatapoints) {
    return { loading, error, deviceCardValues: null };
  }

  const determineOperationMode = (): OperationMode => {
    const discreteValue = getDiscreteValue(SEVADatapointId.OperationMode);

    if (discreteValue === undefined) {
      return OperationMode.UNKNOWN;
    }

    return discreteValue === "off" ? OperationMode.MANUAL : OperationMode.AUTO;
  };

  const determineIfAvailable = (dataPointId: SEVADatapointId): boolean => {
    const discreteValue = getDiscreteValue(dataPointId);

    if (discreteValue === undefined) {
      return false;
    }

    return discreteValue !== "off";
  };

  const getDiscreteValue = (dataPointId: SEVADatapointId) =>
    getMessageTextOrUndefined(discreteDatapointsById.get(dataPointId)?.discreteValue, "en");

  const deviceCardValues: SEVADeviceCardValues = {
    PowerInKW: analogDatapointsById.get(SEVADatapointId.PowerInKW)?.analogValue,
    OperationMode: determineOperationMode(),
    HoursOfLoadRun: analogDatapointsById.get(SEVADatapointId.HoursOfLoadRun)?.analogValue,
    HoursUntilNextMaintenance: analogDatapointsById.get(SEVADatapointId.HoursUntilNextMaintenance)
      ?.analogValue,
    FailuresAvailable: determineIfAvailable(SEVADatapointId.FailuresAvailable),
    AlarmsAvailable: determineIfAvailable(SEVADatapointId.AlarmsAvailable),
    ValuesTimestamp: enrichedDatapoints.timestamp,
    OperationState: getMessageTextOrUndefined(
      discreteDatapointsById.get(SEVADatapointId.OperationState)?.discreteValue,
      currentLanguage
    ),
  };

  return { loading, error, deviceCardValues };
};

import React from "react";
import { DetailValue, StaticDetailValue, DetailValueType } from "../../useDeviceData";

import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Paper,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import { Trans } from "@lingui/macro";

export interface IDeviceDataSectionProps {
  liveDetailsLoadingStatus?: string | undefined;
  values: DetailValue[] | StaticDetailValue[];
  sectionHeader?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      marginTop: 25,
    },
  })
);

const DeviceDataSection: React.FunctionComponent<IDeviceDataSectionProps> = ({
  liveDetailsLoadingStatus,
  values,
  sectionHeader,
}) => {
  const classes = useStyles();
  const type = determineDetailValueType(values);
  const isNotStatic = type !== DetailValueType.STATIC; // live or undefined

  return (
    <div className={classes.content}>
      {sectionHeader && (
        <Typography variant="body1" paragraph>
          <Trans id={sectionHeader} />:
        </Typography>
      )}
      {isNotStatic &&
        liveDetailsLoadingStatus && ( // show information about loading status, but only if liveDetailsLoadingStatus parameter is provided explicitely
          <Typography variant="body1" paragraph>
            <Trans id={liveDetailsLoadingStatus} />
          </Typography>
        )}
      <TableContainer component={Paper}>{createTableBody(values, type)}</TableContainer>
    </div>
  );
};

const createTableBody = (
  values: DetailValue[] | StaticDetailValue[],
  type: DetailValueType | undefined
) => {
  if (type === undefined) {
    return (
      <Table aria-label={"Empty Table"} size="small">
        <TableBody></TableBody>
      </Table>
    );
  }

  if (type !== DetailValueType.LIVE) {
    return (
      <Table aria-label="Static device properties" size="small">
        <TableBody>
          {(values as Array<StaticDetailValue>).map((detailValue, index) =>
            createDetailValueRow(detailValue, index)
          )}
        </TableBody>
      </Table>
    );
  }

  return (
    <Table aria-label={"Live datapoints"} size="small">
      <TableBody>
        {(values as Array<DetailValue>).map((detailValue, index) =>
          createDetailValueRow(detailValue, index)
        )}
      </TableBody>
    </Table>
  );
};

const createDetailValueRow = (
  detailValue: DetailValue | StaticDetailValue,
  key: string | number
) => {
  let descriptionElement;
  if (detailValue.type === DetailValueType.LIVE) {
    descriptionElement = <div>{detailValue.description}</div>;
  } else {
    const descriptior = detailValue.description;
    descriptionElement = <Trans id={descriptior} />;
  }

  return (
    <TableRow key={key}>
      <TableCell component="th" scope="row">
        {descriptionElement}
      </TableCell>
      <TableCell align="right">{detailValue.value ? detailValue.value : "N/A"}</TableCell>
    </TableRow>
  );
};

const determineDetailValueType = (
  values: DetailValue[] | StaticDetailValue[]
): DetailValueType | undefined => {
  if (!values || values.length === 0) {
    return undefined;
  }
  return values[0].type;
};

export default DeviceDataSection;

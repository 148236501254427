import React from "react";
import { Device } from "../../../../../store/extendedApiTypes";
import { useDeviceDetails } from "../../useDeviceData";
import { Grid } from "@material-ui/core";
import { t } from "@lingui/macro";
import DeviceDataSection from "../../commonComponents/deviceDataSection/DeviceDataSection";

// const ROOT_SCOPE: string = "SummaryPage";

export interface IDeviceSummaryProps {
  device: Device;
}

const SEOptimizerDeviceSummary: React.FunctionComponent<IDeviceSummaryProps> = ({
  device,
}): JSX.Element => {
  const { deviceDetails, getLiveDetailsLoadingStatus } = useDeviceDetails(device);

  const liveDetailsLoadingStatus: string | undefined = getLiveDetailsLoadingStatus();
  deviceDetails.staticDetailValues[6].value = "14.12.2020, 14:30:36";

  const liveSummaryValues = [
    {
      type: 1,
      description: "Plantreue bezüglich Energie",
      value: "95,75 %",
    },
    {
      type: 1,
      description: "Startzähler",
      value: "2",
    },
    {
      type: 1,
      description: "Betriebsstunden",
      value: "60 h",
    },
    {
      type: 1,
      description: "Volllaststunden",
      value: "60 h",
    },
    {
      type: 1,
      description: "Maschinenverfügbarkeit",
      value: "75,5 %",
    },
    {
      type: 1,
      description: "Eingespeiste elektrische Energie",
      value: "4780,8 kWh",
    },
  ];

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={6} key={0}>
          <DeviceDataSection
            values={liveSummaryValues}
            sectionHeader={t`Summary`}
          ></DeviceDataSection>
        </Grid>
        <Grid item xs={12} sm={6} lg={6} key={1}>
          <DeviceDataSection
            liveDetailsLoadingStatus={liveDetailsLoadingStatus}
            values={deviceDetails.staticDetailValues}
            sectionHeader={t`Static device properties`}
          ></DeviceDataSection>
        </Grid>
      </Grid>
    </div>
  );
};

export default SEOptimizerDeviceSummary;

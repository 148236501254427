import { Translations } from "@aws-amplify/ui-components";

const germanTranslations = {
  [Translations.BACK_TO_SIGN_IN]: 'Zurück zum Anmelden',
  [Translations.CHANGE_PASSWORD_ACTION]: 'Wechseln',
  [Translations.CHANGE_PASSWORD]: 'Passwort wechseln',
  [Translations.CODE_LABEL]: 'Verifikationscode',
  [Translations.CODE_PLACEHOLDER]: 'Code eingeben',
  [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: 'Bestätigungscode',
  [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: 'Code eingeben',
  [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: 'Anmeldung bestätigen',
  [Translations.CONFIRM_SIGN_UP_LOST_CODE]: 'Bestätigungscode verloren?',
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: 'Code erneut senden',
  [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Bestätigen',
  [Translations.CONFIRM_SMS_CODE]: 'SMS Code bestätigen',
  [Translations.CONFIRM_TOTP_CODE]: 'TOTP Code bestätigen',
  [Translations.CONFIRM]: 'Bestätigen',
  [Translations.CREATE_ACCOUNT_TEXT]: 'Konto anlegen',
  [Translations.EMAIL_LABEL]: 'Emailadresse *',
  [Translations.EMAIL_PLACEHOLDER]: 'Emailadresse eingeben',
  [Translations.FORGOT_PASSWORD_TEXT]: 'Passwort vergessen?',
  [Translations.LESS_THAN_TWO_MFA_VALUES_MESSAGE]: 'Weniger als zwei MFA-Typen verfügbar',
  [Translations.NEW_PASSWORD_LABEL]: 'Neues Passwort',
  [Translations.NEW_PASSWORD_PLACEHOLDER]: 'Passwort eingeben',
  [Translations.NO_ACCOUNT_TEXT]: 'Kein Konto?',
  [Translations.PASSWORD_LABEL]: 'Passwort *',
  [Translations.PASSWORD_PLACEHOLDER]: 'Passwort eingeben',
  [Translations.PHONE_LABEL]: 'Telefonnumber *',
  [Translations.PHONE_PLACEHOLDER]: '(555) 555-1212',
  [Translations.QR_CODE_ALT]: 'QR Code',
  [Translations.RESET_PASSWORD_TEXT]: 'Passwort zurücksetzen',
  [Translations.RESET_YOUR_PASSWORD]: 'Passwort zurücksetzen',
  [Translations.SELECT_MFA_TYPE_HEADER_TEXT]: 'MFA Typ auswählen',
  [Translations.SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT]: 'Verifizieren',
  [Translations.SEND_CODE]: 'Code senden',
  [Translations.SUBMIT]: 'Abschicken',
  [Translations.SETUP_TOTP_REQUIRED]: 'TOTP muss konfiguriert sein',
  [Translations.SIGN_IN_ACTION]: 'Anmelden',
  [Translations.SIGN_IN_HEADER_TEXT]: 'AVAT-WebApp Anmeldung',
  [Translations.SIGN_IN_TEXT]: 'Anmelden',
  [Translations.SIGN_IN_WITH_AMAZON]: 'Anmelden mit Amazon',
  [Translations.SIGN_IN_WITH_AUTH0]: 'Anmelden mit Auth0',
  [Translations.SIGN_IN_WITH_AWS]: 'Anmelden mit AWS',
  [Translations.SIGN_IN_WITH_FACEBOOK]: 'Anmelden mit Facebook',
  [Translations.SIGN_IN_WITH_GOOGLE]: 'Anmelden mit Google',
  [Translations.SIGN_OUT]: 'Ausloggen',
  [Translations.SIGN_UP_EMAIL_PLACEHOLDER]: 'Email',
  [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: 'Schon ein Konto vorhanden?',
  [Translations.SIGN_UP_HEADER_TEXT]: 'Ein neues Konto anlegen',
  [Translations.SIGN_UP_PASSWORD_PLACEHOLDER]: 'Passwort',
  [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Konto anlegen',
  [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: 'Benutzername',
  [Translations.SUCCESS_MFA_TYPE]: '2-Faktorauthentifizierung erfolgreich eingerichtet:',
  [Translations.TOTP_HEADER_TEXT]: 'Bestätigungscode einscannen',
  [Translations.TOTP_LABEL]: 'Sicherheitscode eingeben:',
  [Translations.TOTP_ISSUER]: 'AWSCognito',
  [Translations.TOTP_SETUP_FAILURE]: 'Einrichten der 2-Faktorauthentifizierung fehlgeschlagen ',
  [Translations.TOTP_SUBMIT_BUTTON_TEXT]: 'Sicherheitstoken verifizieren',
  [Translations.TOTP_SUCCESS_MESSAGE]: '2-Faktorauthentifizierung erfolgreich eingerichtet!',
  [Translations.UNABLE_TO_SETUP_MFA_AT_THIS_TIME]: '2-Faktorauthentifizierung konnte nicht eingerichtet werden!',
  [Translations.USERNAME_LABEL]: 'Benutzername *',
  [Translations.USERNAME_PLACEHOLDER]: 'Benutzername eingeben',
  [Translations.VERIFY_CONTACT_EMAIL_LABEL]: 'Email',
  [Translations.VERIFY_CONTACT_HEADER_TEXT]: 'Wiederherstellung des Benutzerzugangs benötigt bestätigte Kontaktdaten',
  [Translations.VERIFY_CONTACT_PHONE_LABEL]: 'Telefonnummer',
  [Translations.VERIFY_CONTACT_SUBMIT_LABEL]: 'Absenden',
  [Translations.VERIFY_CONTACT_VERIFY_LABEL]: 'Verifizieren',
  [Translations.ADDRESS_LABEL]: 'Adresse',
  [Translations.ADDRESS_PLACEHOLDER]: 'Adresse eingeben',
  [Translations.NICKNAME_LABEL]: 'Spitzname',
  [Translations.NICKNAME_PLACEHOLDER]: 'Spitzname eingeben',
  [Translations.BIRTHDATE_LABEL]: 'Geburtstag',
  [Translations.BIRTHDATE_PLACEHOLDER]: 'Geburtstag eingeben',
  [Translations.PICTURE_LABEL]: 'Bild-URL',
  [Translations.PICTURE_PLACEHOLDER]: 'Bild-URL eingeben',
  [Translations.FAMILY_NAME_LABEL]: 'Nachname',
  [Translations.FAMILY_NAME_PLACEHOLDER]: 'Nachname eingeben',
  [Translations.PREFERRED_USERNAME_LABEL]: 'Bevorzugter Benutzername',
  [Translations.PREFERRED_USERNAME_PLACEHOLDER]: 'Bevorzugter Benutzername eingeben',
  [Translations.GENDER_LABEL]: 'Geschlecht',
  [Translations.GENDER_PLACEHOLDER]: 'Geschlecht eingeben',
  [Translations.PROFILE_LABEL]: 'Profil-URL',
  [Translations.PROFILE_PLACEHOLDER]: 'Profil-URL eingeben',
  [Translations.GIVEN_NAME_LABEL]: 'Vorname',
  [Translations.GIVEN_NAME_PLACEHOLDER]: 'Vorname eingeben',
  [Translations.ZONEINFO_LABEL]: 'Zeitzone',
  [Translations.ZONEINFO_PLACEHOLDER]: 'Zeitzone eingeben',
  [Translations.LOCALE_LABEL]: 'Sprache',
  [Translations.LOCALE_PLACEHOLDER]: 'Sprache eingeben',
  [Translations.UPDATED_AT_LABEL]: 'Aktualisiert',
  [Translations.UPDATED_AT_PLACEHOLDER]: 'Zuletzt aktualisiert am',
  [Translations.MIDDLE_NAME_LABEL]: 'Zweiter Vorname',
  [Translations.MIDDLE_NAME_PLACEHOLDER]: 'Zweiten Vorname eingeben',
  [Translations.WEBSITE_LABEL]: 'Website',
  [Translations.WEBSITE_PLACEHOLDER]: 'Website eingeben',
  [Translations.NAME_LABEL]: 'Name',
  [Translations.NAME_PLACEHOLDER]: 'Name eigeben',
  [Translations.CONFIRM_SIGN_UP_FAILED]: 'Benutzerkonto anlegen ist fehlgeschlagen',
  [Translations.SIGN_UP_FAILED]: 'Benutzerkonto anlegen ist fehlgeschlagen'
};

export default germanTranslations;



  
import { Store } from "use-global-hook";
import { GlobalActions, GlobalState } from "../globalStoreTypes";
import { updateUser } from "../../graphql/mutations";
import { UpdateUserInput } from "../../API";
import { graphqlOperation, API } from "aws-amplify";
import { UpdateUserMutation } from "../../API";

const updateUserData = async (
  store: Store<GlobalState, GlobalActions>,
  updateInput: UpdateUserInput
): Promise<void> => {
  let updatedUserData = store.state.userData;

  try {
    const { data } = (await API.graphql(graphqlOperation(updateUser, { input: updateInput }))) as {
      data: UpdateUserMutation;
    };

    updatedUserData = data.updateUser;
  } catch (error) {
    throw error;
  } finally {
    // Call set state even if no changes have been made to have a consistent GUI state.
    let modifiedUserData = { ...store.state.userData };
    modifiedUserData = { preferedLocale: updatedUserData.preferedLocale };
    store.setState({ ...store.state, userData: modifiedUserData });
  }
};

export default updateUserData;

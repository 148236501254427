import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";

const LOCALES_WITH_COMMA_SEPARATOR = ["de"];

export const LOCAL_STORAGE_KEY = "AVAT_WEBAPP_CURRENT_LANGUAGE";
export const FALLBACK_LANGUAGE = "en";

export const LOCALES_WITH_DESCRIPTION = [
  { value: "de", text: "Deutsch" },
  { value: "en", text: "English" },
];

const getMaterialUiTableLocalizationDefs = (i18n: I18n) => ({
  body: {
    emptyDataSourceMessage: t`No records to display`,
    editTooltip: t`Edit`,
    editRow: {
      cancelTooltip: t`Cancel`,
      saveTooltip: t`Save`,
    },
  },
  pagination: {
    labelDisplayedRows: `{from}-{to} ${t`of`} {count}`,
    labelRowsSelect: t`rows`,
    firstTooltip: t`First Page`,
    previousTooltip: t`Previous Page`,
    nextTooltip: t`Next Page`,
    lastTooltip: t`Last Page`,
  },
  header: {
    actions: t`Actions`,
  },
  toolbar: {
    searchTooltip: t`Search`,
    searchPlaceholder: t`Search`,
  },
});

const localizeNumber = (locale: string, number: number): string =>
  LOCALES_WITH_COMMA_SEPARATOR.includes(locale)
    ? number.toString().replace(".", ",")
    : number.toString();

const getBrowserLocale = (): string => {
  const browserLocales =
    navigator.languages === undefined ? [navigator.language] : navigator.languages;

  if (!browserLocales || browserLocales.length === 0) {
    return FALLBACK_LANGUAGE;
  }

  const locales = browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();
    return trimmedLocale.split(/-|_/)[0];
  });

  return locales[0];
};

const determineCurrentLanguage = (): string => {
  let language = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!language) {
    language = getBrowserLocale();
  }
  return language;
};

export { getMaterialUiTableLocalizationDefs, localizeNumber, determineCurrentLanguage };

import React from "react";
import { OperationMode } from "../../deviceCard/openECSDeviceCard/useOpenECSDeviceCardValues";
import ImageWithCounterRenderer from "../imageWithCounterRenderer";
import { deviceToLinkElement } from "../../navigation/deviceNavigationUtils";
import { Device } from "../../../store/extendedApiTypes";
import { Link } from "react-router-dom";

import alarmIcon from "../../../images/warning_modavat_24_enabled.svg";
import failureIcon from "../../../images/failure_modavat_24_enabled.svg";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ManualIcon from "@material-ui/icons/PanToolOutlined";
import AutoIcon from "@material-ui/icons/DataUsageOutlined";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerContainer: {
      width: "130px",
      height: "40px",
      margin: "10px 15px 15px 10px",
    },
    icon: {
      color: theme.palette.secondary.main,
    },
    operationModeIconContainer: {
      padding: "5px 0px 5px 1px",
    },
  })
);

const useFailureStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {},
    iconText: {
      //color: "#ef0000" // color of the lightning icon
    },
  })
);

const useAlarmStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {},
    iconText: {
      //color: "#ed8c00" // color of the alarm icon
    },
  })
);

interface IAlarmEventsRendererProps {
  device: Device;
  NumberOfFailures: number | undefined;
  NumberOfAlarms: number | undefined;
  operationMode: OperationMode | undefined;
}

const AlarmsAndOperationModeRenderer: React.FunctionComponent<IAlarmEventsRendererProps> = ({
  device,
  NumberOfFailures,
  NumberOfAlarms,
  operationMode,
}) => {
  const classes = useStyles();
  const failureClasses = useFailureStyles();
  const alarmClasses = useAlarmStyles();

  const deviceAlarmsLink = `/fleet/device/${deviceToLinkElement(device)}/alarms`;

  return (
    <div className={classes.outerContainer}>
      <Grid container spacing={2}>
        {(NumberOfFailures === undefined || NumberOfFailures > 0) && (
          <Grid item xs={4} component={Link} to={deviceAlarmsLink}>
            <ImageWithCounterRenderer
              iconSource={failureIcon}
              counter={NumberOfFailures}
              styles={failureClasses}
            />
          </Grid>
        )}
        {NumberOfFailures !== undefined && NumberOfFailures === 0 && (
          <Grid item xs={4}>
            {/* Just as placeholder without link */}
          </Grid>
        )}
        {(NumberOfAlarms === undefined || NumberOfAlarms > 0) && (
          <Grid item xs={4} component={Link} to={deviceAlarmsLink}>
            <ImageWithCounterRenderer
              iconSource={alarmIcon}
              counter={NumberOfAlarms}
              styles={alarmClasses}
            />
          </Grid>
        )}
        {NumberOfAlarms !== undefined && NumberOfAlarms === 0 && (
          <Grid item xs={4}>
            {/* Just as placeholder without link */}
          </Grid>
        )}
        <Grid item xs={4}>
          <div className={classes.operationModeIconContainer}>
            {operationMode &&
              (operationMode === OperationMode.MANUAL ? (
                <ManualIcon className={classes.icon} />
              ) : (
                <AutoIcon className={classes.icon} />
              ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AlarmsAndOperationModeRenderer;

// NOTE: this currently has to be a js file, since it is used by cypress and our cypress is currently not configured to use ts

// App configuration for production account
const cognitoUserPoolConfig = {
  userPoolId: "eu-central-1_gBRUcLGbe", // common-prod-Customer-User-Pool
  userPoolWebClientId: "3u3vki7d07a8922r1c87tahsi8" // "customerLogin" app client id
};

const appSyncConfig = {
  aws_appsync_graphqlEndpoint:
    "https://cqkr2eutnfekljosyzm6c3t3hi.appsync-api.eu-central-1.amazonaws.com/graphql",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_region: "eu-central-1"
};

export { cognitoUserPoolConfig, appSyncConfig };

import React from "react";
import { Device } from "../../../../../store/extendedApiTypes";
import { DetailValue, useDeviceDetails } from "../../useDeviceData";
import { Grid, Typography } from "@material-ui/core";
import { t, Trans } from "@lingui/macro";
import DeviceDataSection from "../../commonComponents/deviceDataSection/DeviceDataSection";

const ROOT_SCOPE: string = "SummaryPage";

export interface IDeviceSummaryProps {
  device: Device;
}

const CustomDeviceSummary: React.FunctionComponent<IDeviceSummaryProps> = ({ device }): JSX.Element => {
  const { deviceDetails, getLiveValuesForScope, getLiveDetailsLoadingStatus } = useDeviceDetails(
    device
  );

  const liveDetailsLoadingStatus: string | undefined = getLiveDetailsLoadingStatus();

  const liveSummaryValues = getLiveValuesForScope(ROOT_SCOPE, 0).get("QuickView") as DetailValue[];

  return (
    <div>
      <Grid container spacing={3}>
        {/* live data */}
        {liveDetailsLoadingStatus && (
          <Grid item xs={12} sm={6} lg={6} key={0}>
            <Typography variant="body1" paragraph>
              <Trans id={liveDetailsLoadingStatus} />
            </Typography>
          </Grid>
        )}
        {liveSummaryValues && liveSummaryValues.length > 0 && (
          <Grid item xs={12} sm={6} lg={6} key={0}>
            <DeviceDataSection
              liveDetailsLoadingStatus={liveDetailsLoadingStatus}
              values={liveSummaryValues}
              sectionHeader={t`Summary`} 
            ></DeviceDataSection>
          </Grid>
        )}
        {/* static data */}
        <Grid item xs={12} sm={6} lg={6} key={1}>
          <DeviceDataSection
            liveDetailsLoadingStatus={liveDetailsLoadingStatus} 
            values={deviceDetails.staticDetailValues}
            sectionHeader={t`Static device properties`}
          ></DeviceDataSection>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomDeviceSummary;

import React from "react";
import { Device } from "../../store/extendedApiTypes";
import Typography from "@material-ui/core/Typography";
import { localizeNumber } from "../auth/i18nUtils";
import { I18n } from "@lingui/core";

const getAnalogValueLabel = (
  locale: string,
  analogValue: number | undefined,
  unit?: string | undefined
): string =>
  analogValue !== undefined
    ? `${localizeNumber(locale, analogValue)} ${unit !== undefined ? unit : ""}`
    : "N/A";

const getDiscreteValueLabel = (discreteValue: string | undefined): string =>
  discreteValue !== undefined && discreteValue !== "" ? discreteValue : "N/A";

const getDeviceEngineName = (device: Device): string => (device.engine ? device.engine : device.id);

const getDevicePlantName = (device: Device): string => (device.plant ? device.plant : "");

const getFormattedDateTimeLabel = (className: string, timestamp: string, i18n: I18n) => {
  return (
    <Typography variant="overline" align="right" component="div" className={className} noWrap>
      {i18n.date(new Date(timestamp), {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      })}
    </Typography>
  );
};
export {
  getAnalogValueLabel,
  getDiscreteValueLabel,
  getDeviceEngineName,
  getDevicePlantName,
  getFormattedDateTimeLabel,
};

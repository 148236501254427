import navigationStructure from "./mainNavigationStructure";
import NavigationItem from "./navigationItem";

/**
 * A custom hook which returns the main navigation item, the app is currently located at
 * @param location the location object yielded by react-routers useLocation() hook
 */
function useLocationInMainNavigation(location: Partial<Location>): NavigationItem | null {
  let itemsFound: NavigationItem[] = [];

  navigationStructure.items.forEach((item: NavigationItem) => {
    if (location.pathname && location.pathname.startsWith(item.url)) {
      itemsFound.push(item);
    }
  });

  if (itemsFound.length > 1) {
    throw new Error(
      `Found more than one main navigation items matching the current location. Location: ${
        location.pathname
      }; Items: ${JSON.stringify(itemsFound)}`
    );
  }

  return itemsFound.length === 0 ? null : itemsFound[0];
}

export default useLocationInMainNavigation;

import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";

interface IUserContextValue {
  currentAuthenticatedUsername: string;
  noAuthenticatedUser: boolean;
}

interface IContextProviderProps {
  children: any; // TODO
}

const NO_AUTHENTICATED_USER = "No authenticated user!";

const UserContext = React.createContext<IUserContextValue>({
  currentAuthenticatedUsername: NO_AUTHENTICATED_USER,
  noAuthenticatedUser: true
});

export const UserContextProvider: React.FunctionComponent<IContextProviderProps> = ({
  children
}) => {
  const [currentUsername, setCurrentUsername] = useState(NO_AUTHENTICATED_USER);

  useEffect(() => {
    fetchCurrentUsername();
  });

  const fetchCurrentUsername = async () => {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (error) {
      console.error(error);
    }
    const username = user ? user.username : NO_AUTHENTICATED_USER;
    setCurrentUsername(username);
  };

  return (
    <UserContext.Provider value={{ currentAuthenticatedUsername: currentUsername, noAuthenticatedUser: currentUsername === NO_AUTHENTICATED_USER }}>
      {children}
    </UserContext.Provider>
  );
};

export const Consumer = UserContext.Consumer;

export default UserContext;

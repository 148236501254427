import React  from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerContainer: {
      padding: "5px 10px 5px 1px",
      display: "inline-flex",
      verticalAlign: "top",
      position: "relative",
      margin: "0px 20px 0px 1px"
    },
    icon : {
    },
    iconText: { 
      color: theme.palette.secondary.dark,  
      position: "absolute",
      top: "5%", 
      left: "70%"
    }
  })
);

interface IImageWithCounterRendererProps {
  iconSource: string;
  counter?: number | undefined;
  styles: Record<string, string>;
}

const ImageWithCounterRenderer: React.FunctionComponent<IImageWithCounterRendererProps> = ({iconSource, counter, styles}) => {
  const classes = useStyles();  

  return (
    <div className={classes.outerContainer}>
      <img className={styles.icon ? `${styles.icon} ${classes.icon}` : classes.icon} src={iconSource} alt={counter !== undefined ? counter.toString() : ""} />
      <div className={styles.iconText ? `${styles.iconText} ${classes.iconText}` : classes.iconText}>
        {counter !== undefined ? counter.toString() : ""}
      </div>
    </div>
  );
}

export default ImageWithCounterRenderer;
import React, { useContext, useEffect } from "react";
import UserContext from "./UserContextProvider";
import useGlobal from "../../store";

interface IGlobalStateInitializerProps {
  children: any;
}

const GlobalStateInitializer: React.FunctionComponent<IGlobalStateInitializerProps> = ({
  children,
}) => {
  const { currentAuthenticatedUsername, noAuthenticatedUser } = useContext(UserContext);

  const [, actions] = useGlobal(
    (state) => state.devices,
    (actions) => actions
  );

  useEffect(() => {
    if (!noAuthenticatedUser) {
      actions.queryDevices(currentAuthenticatedUsername);
    }
  }, [currentAuthenticatedUsername, noAuthenticatedUser, actions]);

  useEffect(() => {
    if (!noAuthenticatedUser) {
      actions.queryUserData();
    }
  }, [noAuthenticatedUser, actions]);

  return <>{children}</>;
};

export default GlobalStateInitializer;

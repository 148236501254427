import * as React from "react";
import { Switch, Route, RouteComponentProps, Redirect } from "react-router-dom";
import DeviceBottomNavigation from "../../../navigation/deviceBottomNavigation/DeviceBottomNavigation";
import DeviceTabNavigation from "../../../navigation/deviceTabNavigation";
import DeviceSummary from "../deviceSummary";
import DeviceDetails from "../deviceDetails";
import DeviceAlarms from "../deviceAlarms";
import DeviceBreadcrumbs from "../../../navigation/deviceBreadcrumbs";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import deviceMatchParams from "../../deviceMatchParams";
import { Device } from "../../../../store/extendedApiTypes";
import useGlobal from "../../../../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: 5,
      marginBottom: 10,
      color: theme.palette.primary.main,
    },
    breadcrumb: {
      marginTop: -10,
    },
    menuButton: {
      color: theme.palette.secondary.dark,
      textAlign: "left",
    },
  })
);

interface IDeviceMainProps extends RouteComponentProps<deviceMatchParams> {}

const DeviceMain: React.FunctionComponent<IDeviceMainProps> = ({ match }) => {
  const deviceId = match.params.deviceId;
  const deviceType = match.params.deviceType;

  const [{ devices }] = useGlobal();
  const device: Device | undefined = devices.find(
    (currentDevice) => currentDevice.id === deviceId && currentDevice.type === deviceType
  );

  const classes = useStyles();

  return (
    <div>
      <Hidden smUp>
        <DeviceBreadcrumbs deviceId={deviceId} deviceType={deviceType} />
      </Hidden>
      <Typography variant="h4" className={classes.title}>
        {device?.engine ? device.engine : deviceId}
      </Typography>
      <Hidden xsDown>
        <DeviceTabNavigation deviceId={deviceId} deviceType={deviceType} />
      </Hidden>
      <div>
        <Switch>
          <Route path="/fleet/device/:deviceType/:deviceId/summary" component={DeviceSummary} />
          <Route path="/fleet/device/:deviceType/:deviceId/details" component={DeviceDetails} />
          <Route path="/fleet/device/:deviceType/:deviceId/alarms" component={DeviceAlarms} />
          <Redirect
            from="/fleet/device/:deviceType/:deviceId"
            to="/fleet/device/:deviceType/:deviceId/summary"
          />
        </Switch>
      </div>
      <Hidden smUp>
        <DeviceBottomNavigation deviceId={deviceId} deviceType={deviceType} />
      </Hidden>
    </div>
  );
};

export default DeviceMain;

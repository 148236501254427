import React from "react";
import PWAPrompt from "react-ios-pwa-prompt";

interface Props {}

const IOSPwaPrompt: React.FunctionComponent<Props> = (props) => {
  return (
    <PWAPrompt
      timesToShow={3}
      copyTitle="Add to Home Screen"
      copyBody="This website has app functionality. Add it to your home screen to use it in fullscreen and while offline."
      copyShareButtonLabel="Press the 'Share' button."
      copyAddHomeButtonLabel="Press 'Add to Home Screen'"
      copyClosePrompt="Cancel"
      permanentlyHideOnDismiss={false}
    />
  );
};

export default IOSPwaPrompt;

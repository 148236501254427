import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { deviceToLinkElement } from "../../navigation/deviceNavigationUtils";
import { useDeviceCardStyles } from "../DeviceCard.styles";
import { IDeviceCardProps } from "../DeviceCard";
import { AlarmsAndOperationModeRendererForTEM } from "../../dataPointRenderers/alarmsAndOperationModeRenderer";
import engine from "../../../images/AVAT_Piktogramm_Gasmotor_RGB.png";
import LabeledIconRenderer from "../../dataPointRenderers/labeledIconRenderer";
import { useSEVADeviceCardValues, SEVADeviceCardValues } from "./useSEVADeviceCardValues";
import {
  useStyles,
  useCardHeaderStyles,
  useCardBodyAndActionStyles,
} from "./SEVADeviceCard.styles";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Grid, CardHeader, Avatar, Typography } from "@material-ui/core";
import OperationhoursIcon from "@material-ui/icons/UpdateOutlined";
import NextMaintenanceIcon from "@material-ui/icons/BuildOutlined";
import PowerIcon from "@material-ui/icons/SpeedRounded";
import Hidden from "@material-ui/core/Hidden";
import { Trans } from "@lingui/macro";
import LanguageContext from "../../hoc/LanguageContextProvider";

import {
  getAnalogValueLabel,
  getDeviceEngineName,
  getDevicePlantName,
  getDiscreteValueLabel,
  getFormattedDateTimeLabel,
} from "../deviceCardUtils";
import { useLingui } from "@lingui/react";
import OperationStateIcon from "@material-ui/icons/InfoOutlined";

const SEVADeviceCard: React.FunctionComponent<IDeviceCardProps> = ({ device }) => {
  const commonCardStyles = useDeviceCardStyles();
  const classes = useStyles();

  const cardHeaderStyles = useCardHeaderStyles();
  const cardBodyAndActionStyles = useCardBodyAndActionStyles();
  const { i18n } = useLingui();

  const { currentLanguage } = useContext(LanguageContext);
  const { loading, error, deviceCardValues } = useSEVADeviceCardValues(device);

  const isLiveContentAvailable = !loading && !error && device.dataModel && deviceCardValues;

  return (
    <Card className={`${commonCardStyles.root} SEVADeviceCard`}>
      <CardHeader
        avatar={<Avatar aria-label="SEVA Engine" className={classes.avatar} src={engine} />}
        action={
          isLiveContentAvailable && (
            <AlarmsAndOperationModeRendererForTEM
              device={device}
              failuresAvailable={deviceCardValues?.FailuresAvailable}
              alarmsAvailable={deviceCardValues?.AlarmsAvailable}
              operationMode={deviceCardValues?.OperationMode}
            />
          )
        }
        title={getDeviceEngineName(device)}
        subheader={getDevicePlantName(device)}
        classes={cardHeaderStyles}
      />

      <CardContent classes={cardBodyAndActionStyles}>
        {isLiveContentAvailable &&
          deviceCardValues &&
          getLiveDatapointsContent(currentLanguage, deviceCardValues)}
        {loading && <Typography variant="body2">Loading...</Typography>}
        {!isLiveContentAvailable && !error && !loading && (
          <Typography variant="body2">No live content available.</Typography>
        )}
        {!isLiveContentAvailable && error && (
          <Typography variant="body2">No live content available. An error occured!</Typography>
        )}
      </CardContent>

      <CardActions disableSpacing classes={cardBodyAndActionStyles}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              className={classes.actionButton}
              size="small"
              component={Link}
              to={`/fleet/device/${deviceToLinkElement(device)}`}
            >
              <Trans>Show details</Trans>
            </Button>
          </Grid>
          <Grid item xs={6}>
            {isLiveContentAvailable &&
              deviceCardValues?.ValuesTimestamp !== undefined &&
              getFormattedDateTimeLabel(
                classes.lastUpdatedTime,
                deviceCardValues?.ValuesTimestamp,
                i18n
              )}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

function getLiveDatapointsContent(locale: string, deviceCardValues: SEVADeviceCardValues) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <LabeledIconRenderer
            icon={PowerIcon}
            label={getAnalogValueLabel(locale, deviceCardValues.PowerInKW, "kW")}
          />
        </Grid>
        <Grid item xs={6}>
          <LabeledIconRenderer
            icon={OperationStateIcon}
            label={getDiscreteValueLabel(deviceCardValues.OperationState)}
          />
        </Grid>
      </Grid>
      <Hidden xsDown>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LabeledIconRenderer
              icon={OperationhoursIcon}
              label={getAnalogValueLabel(locale, deviceCardValues.HoursOfLoadRun, "h")}
            />
          </Grid>
          <Grid item xs={6}>
            <LabeledIconRenderer
              icon={NextMaintenanceIcon}
              label={getAnalogValueLabel(locale, deviceCardValues.HoursUntilNextMaintenance, "h")}
            />
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
}

export default SEVADeviceCard;

import { useContext } from "react";
import { useDatapointsForDisplay } from "../../../store/datapoints/useDatapointsForDisplay";
import { Device, Tag, UNTAGGED } from "../../../store/extendedApiTypes";
import {
  EnrichedAnalogDatapoint,
  EnrichedDiscreteDatapoint,
  EnrichedDatapoints,
  EnrichedDatapoint,
} from "../../../store/datapoints/customDatapointTypes";
import { getMessageText, getMessageTextOrUndefined } from "../../../store/extendedApiUtils";
import { t } from "@lingui/macro";
import LanguageContext from "../../hoc/LanguageContextProvider";
import { localizeNumber } from "../../auth/i18nUtils";
import sortBy from "lodash/sortBy"; 

export enum DetailValueType {
  STATIC,
  LIVE,
}

interface BaseDetailValue {
  type: DetailValueType;
  value: string | undefined;
}

export interface DetailValue extends BaseDetailValue {
  description: string;
}

export interface StaticDetailValue extends BaseDetailValue {
  description: string;
}

export interface DeviceDetails {
  staticDetailValues: StaticDetailValue[];
  liveDetailValues: DetailValue[];
  liveDetailsTimestamp: string | undefined;
  groupsByTag: Map<Tag, EnrichedDatapoint[]>;
}

export interface UseDeviceDetailsState {
  liveDetailsLoading: boolean;
  liveDetailsError: any;
  deviceDetails: DeviceDetails;
  /**
   * Retrieve the current live value if available
   */
  getLiveValueForId(id: string): DetailValue | undefined;
  /**
   * Retrieve all live values for a specific tag. However instead of a tag, the parameter is a scope (which is a part of a tag).
   * The function returns a mapping of the child-scopes of the rootScope to corresponding liveValues
   *
   * @param rootScope the part of the tag which all results share.
   * @param rootScopeLevel the position of the rootScope within the tag
   *
   * Example:
   * The following Tags are present in the data model:
   * 1: PageA_PartX_Section1
   * 2: PageA_PartX_Section2
   * 3: PageA_PartY_Section1
   * 4: PageA_PartY_Section2
   * 5: PageB_PartX_Section1
   *
   * getLiveValuesForScope("PageA", 0)
   * will yield a map with
   * {
   *    PartX -> values which are tagged "PartX"  (1, 2)
   *    PartY -> values which are tagged "PartY"  (3, 4)
   * }
   * and mark 5 as UNTAGGED (as it only is interessted in the rootScope "PageA")
   *
   * whereas
   * getLiveValuesForScope("PartX", 1)
   * will yield a map with
   * {
   *    Section1 -> values which are tagged "Section1"  (1, 3, 5)
   * }
   * and mark 2 and 4 as UNTAGGED (as it only is interessted in the rootScope "PartX")
   *
   */
  getLiveValuesForScope(rootScope: string, rootScopeLevel: number): Map<string, DetailValue[]>;
  /**
   * Yields a translatable which can be used to indicate the current loading status
   */
  getLiveDetailsLoadingStatus(): string | undefined;
}

export const useDeviceDetails = (device: Device): UseDeviceDetailsState => {
  const { currentLanguage } = useContext(LanguageContext);

  const {
    loading,
    error,
    enrichedDatapoints,
    analogDatapointsById,
    discreteDatapointsById,
    groupsByTag,
  } = useDatapointsForDisplay(device);

  // Add static values
  const staticDetailValues: StaticDetailValue[] = [
    createStaticDetailValue(t`Serial number`, device.id),
    createStaticDetailValue(t`Device type`, device.type),
    createStaticDetailValue(t`Application`, device.appName),
    createStaticDetailValue(t`Plant`, device.plant),
    createStaticDetailValue(t`Engine`, device.engine),
    createStaticDetailValue(t`Time zone`, device.timeZone),
    createStaticDetailValue(
      t`Time of last update`,
      getLastUpdatedString(enrichedDatapoints, currentLanguage)
    ),
  ];

  // Add live values if present
  const idToLiveValueLookupTable: Map<Tag, DetailValue> | undefined = new Map();
  const liveDetailValues: DetailValue[] = [];
  if (enrichedDatapoints) {
    analogDatapointsById.forEach((analogDatapoint) => {
      const detailValue: DetailValue = getDetailValueFromAnalogDp(analogDatapoint, currentLanguage);
      if (detailValue.value !== undefined) {
        liveDetailValues.push(detailValue);
        idToLiveValueLookupTable.set(analogDatapoint.id, detailValue);
      }
    });

    discreteDatapointsById.forEach((discreteDatapoint) => {
      const detailValue: DetailValue = getDetailValueFromDiscreteDp(
        discreteDatapoint,
        currentLanguage
      );
      if (detailValue.value !== undefined) {
        liveDetailValues.push(detailValue);
        idToLiveValueLookupTable.set(discreteDatapoint.id, detailValue);
      }
    });
  }

  const deviceDetails: DeviceDetails = {
    staticDetailValues,
    liveDetailValues,
    liveDetailsTimestamp: enrichedDatapoints?.timestamp,
    groupsByTag,
  };

  function lookupLiveValue(id: string): DetailValue | undefined {
    if (idToLiveValueLookupTable) {
      return idToLiveValueLookupTable.get(id);
    }
    return undefined;
  }

  function lookupLiveValuesForScope(
    rootScope: string,
    rootScopeLevel: number
  ): Map<string, DetailValue[]> {
    return lookupLiveValuesForScopeHelper(rootScope, rootScopeLevel, groupsByTag, lookupLiveValue);
  }

  function computeLiveDetailsLoadingStatus(): string | undefined {
    const isLiveContentAvailable = deviceDetails.liveDetailsTimestamp !== undefined;
    if (loading) {
      return t`Loading...`;
    }
    if (!isLiveContentAvailable && !error && !loading) {
      return t`No live content available`;
    }
    if (!isLiveContentAvailable && error) {
      return t`No live content available. An error occured!`;
    }
  }

  return {
    liveDetailsLoading: loading,
    liveDetailsError: error,
    deviceDetails: deviceDetails,
    getLiveValueForId: lookupLiveValue,
    getLiveValuesForScope: lookupLiveValuesForScope,
    getLiveDetailsLoadingStatus: computeLiveDetailsLoadingStatus,
  };
};

// helper function (extracted for easier testing); export for testing
export function lookupLiveValuesForScopeHelper(
  rootScope: string,
  rootScopeLevel: number,
  groupsByTag: Map<string, EnrichedDatapoint[]>,
  liveValueProviderFunction: (id: string) => DetailValue | undefined
) {
  const scopeToDetailValues: Map<string, DetailValue[]> = new Map<string, DetailValue[]>();

  groupsByTag.forEach((dataPoints: EnrichedDatapoint[], tag: Tag) => {
    const scopes: string[] = tag.replace("#", "").split("_");
    let detailValues: Array<DetailValue> = new Array<DetailValue>();
    const currentScope = scopes[rootScopeLevel + 1];

    if (scopes[rootScopeLevel] === rootScope || tag === UNTAGGED) {
      dataPoints.forEach((enchrichedDatapoint: EnrichedDatapoint) => {
        const detailValue = liveValueProviderFunction(enchrichedDatapoint.id);
        if (detailValue) {
          detailValues.push(detailValue);
        }
      });
    }

    if (detailValues.length !== 0) {
      detailValues = sortBy(detailValues, ["description"]);
      if (currentScope) {
        scopeToDetailValues.set(currentScope, detailValues);
      } else {
        scopeToDetailValues.set(UNTAGGED, detailValues);
      }
    }
  });

  return scopeToDetailValues;
}

const createStaticDetailValue = (
  description: string,
  nullableValue: string | null
): StaticDetailValue => {
  const value: string | undefined = nullableValue ? nullableValue : undefined;
  return {
    type: DetailValueType.STATIC,
    description,
    value,
  };
};

const getDetailValueFromAnalogDp = (
  analogDatapoint: EnrichedAnalogDatapoint,
  locale: string
): DetailValue => {
  const unitSuffix: string =
    analogDatapoint.unit && analogDatapoint.unit.length > 0 ? " " + analogDatapoint.unit : "";
  return {
    type: DetailValueType.LIVE,
    description: getMessageText(analogDatapoint.text, locale),
    value: `${localizeNumber(locale, analogDatapoint.analogValue)} ${unitSuffix}`,
  };
};

const getDetailValueFromDiscreteDp = (
  discreteDatapoint: EnrichedDiscreteDatapoint,
  locale: string
): DetailValue => {
  return {
    type: DetailValueType.LIVE,
    description: getMessageText(discreteDatapoint.text, locale),
    value: getMessageTextOrUndefined(discreteDatapoint.discreteValue, locale),
  };
};

function getLastUpdatedString(
  enrichedDatapoints: EnrichedDatapoints | null,
  locale: string
): string {
  if (enrichedDatapoints && enrichedDatapoints.timestamp) {
    const lastUpdated: Date = new Date(enrichedDatapoints?.timestamp);
    return lastUpdated.toLocaleString(locale);
  } else {
    return "";
  }
}

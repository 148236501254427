import { NavigationItems } from "./navigationItem";
import AlarmIcon from "@material-ui/icons/Alarm";
// import GeneralIcon from "@material-ui/icons/AllInclusive";
import { t } from "@lingui/macro";

const settingsNavigationStructure: NavigationItems = {
  items: [
    {
      desc: "alarmsItem",
      label: t`Alarms`,
      key: 0,
      icon: AlarmIcon,
      url: "/settings/alarms"
    },
    // {
    // desc: "generalItem",
    // label: t`General`,
    // key: 1, 
    // icon: GeneralIcon,
    // url: "/settings/general"
    // }
  ]
};

export default settingsNavigationStructure;

import React, { useEffect, useState } from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Hidden, Paper, Typography, Grid } from "@material-ui/core";

import { Trans, t } from "@lingui/macro";
import { I18n as i18nCore } from "@lingui/core";
import SelectionBox from "../../basics/selectionBox/SelectionBox";
import useGlobal from "../../../store/index";
import { UserData } from "../../../store/extendedApiTypes";
import { FALLBACK_LANGUAGE, LOCALES_WITH_DESCRIPTION } from "../../auth/i18nUtils";
import { UpdateUserInput } from "../../../API";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionHeading: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    section: {
      paddingBottom: theme.spacing(1),
    },
  })
);

interface IGeneralSettingsSectionProps {
  i18n: i18nCore;
}

const GeneralSettingsSection: React.FunctionComponent<IGeneralSettingsSectionProps> = ({
  i18n,
}) => {
  const classes = useStyles();

  const [{ userData }, { updateUserData }] = useGlobal();
  const [userMetaData, setUserData] = useState<UserData>({ preferedLocale: FALLBACK_LANGUAGE });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setUserData({ ...userData });
  }, [userData]);

  const handleChange = async (event: React.ChangeEvent<{ value: unknown }>): Promise<any> => {
    const selectedLocale = event.target.value as string;
    setUserData({ ...userMetaData, preferedLocale: selectedLocale });
    await storeChange(selectedLocale);
  };

  const storeChange = async (locale: string) => {
    const updateInput: UpdateUserInput = {
      preferedLocale: locale,
    };
    try {
      await updateUserData(updateInput);
    } catch (error) {
      enqueueSnackbar(
        t`Error when saving new locale setting. Please check internet connetion and try again!`,
        { variant: "error", preventDuplicate: true, autoHideDuration: 2000 }
      );
    }
  };

  return (
    <Grid item xs={12}>
      <Paper elevation={1} className={classes.section}>
        <Typography variant="h6" className={classes.sectionHeading}>
          <Trans>General settings</Trans>
        </Typography>
        <Hidden xsDown>
          <Grid container direction="row" alignItems="stretch">
            <Grid item xs={6}>
              {getLanguageSelectionBox(i18n, userMetaData.preferedLocale, handleChange)}
            </Grid>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item xs={10}>
            {getLanguageSelectionBox(i18n, userMetaData.preferedLocale, handleChange)}
          </Grid>
        </Hidden>
      </Paper>
    </Grid>
  );
};

const getLanguageSelectionBox = (
  i18n: i18nCore,
  value: string,
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void
): JSX.Element => (
  <SelectionBox
    label={t`Notification language`}
    options={LOCALES_WITH_DESCRIPTION}
    value={value}
    onChange={handleChange}
  ></SelectionBox>
);

export default GeneralSettingsSection;

import { Message } from "./extendedApiTypes";
import { t } from "@lingui/macro";

export const getMessageText = (messages: Message[], locale: string): string => {
  if (messages.length > 0) {
    const langValue = messages.find((discreteValue: Message) => discreteValue.langCode === locale);
    return langValue ? langValue.text : messages[0].text;
  } else {
    return "";
  }
};

const MESSAGE_TYPES: { [key: string]: string } = {
  ALARM: t`ALARM`,
  FAILURE: t`FAILURE`,
};

export const getMessageType = (
  messageType: string
): { original: string; type: string; extension?: string | undefined } => {
  const parts = messageType.split("_");

  return {
    original: messageType,
    type: MESSAGE_TYPES[parts[0].toUpperCase()],
    ...(parts.length === 2 && { extension: ` ${parts[1]}` }),
  };
};

export const getMessageTextOrUndefined = (
  messages: Message[] | undefined,
  locale: string
): string | undefined => {
  if (messages && messages.length > 0) {
    return getMessageText(messages, locale);
  } else {
    return undefined;
  }
};

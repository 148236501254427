import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.contrastText,
    },
    actionButton: {
      color: theme.palette.secondary.main,
      fontWeight: "bold",
      "&:hover": {
        textDecoration: "underline",
        color: theme.palette.primary.main,
        backgroundColor: "white",
      },
      "&:active": {
        textDecoration: "underline",
        color: theme.palette.primary.main,
        backgroundColor: "white",
      },
    },
    lastUpdatedTime: {
      color: theme.palette.secondary.main,
      padding: "0px 5px 0px 0px",
    },
  })
);

// const useCardHeaderStyles= makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       borderBottom: "1px solid",
//       borderBottomColor: theme.palette.primary.main,
//       [theme.breakpoints.down("sm")]: {
//         padding: theme.spacing(1),
//       },
//     },
//     title: {
//       fontSize: "1.3em",
//       fontWeight: "bold",
//     },
//   })
// );

const useCardBodyAndActionStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1),
      },
    },
  })
);

export { useStyles, useCardBodyAndActionStyles };

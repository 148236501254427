import { UpdateAtaConfigInput, UpdateUserInput } from "../API";
import { Device, UserData } from "./extendedApiTypes";

export type GlobalState = {
  userData: UserData;
  devices: Device[];
  devicesState: DevicesState;
};

export enum DevicesState {
  NOT_INITIALIZED,
  LOADING,
  INITIALIZED,
  UPDATED,
  NO_DEVICES_FOR_USER,
  ERROR,
}

export type GlobalActions = {
  queryUserData: () => Promise<void>;
  updateUserData: (updateInput: UpdateUserInput) => Promise<void>;
  queryDevices: (username: string) => Promise<void>;
  updateAtaConfig: (updateInput: UpdateAtaConfigInput) => Promise<void>;
};

import AlarmIcon from "@material-ui/icons/Alarm";
import ViewListIcon from "@material-ui/icons/ViewList";
import InfoItem from "@material-ui/icons/Info";
import NavigationItem from "./navigationItem";
import { Device } from "../../store/extendedApiTypes";
import deviceMatchParams from "../routes/deviceMatchParams";
import { t } from "@lingui/macro";

const getNavigationItems = (params: deviceMatchParams): NavigationItem[] => {
  let items: NavigationItem[] = [];

  items.push({
    desc: "summaryItem",
    label: t`Summary`,
    key: 0,
    icon: InfoItem,
    url: `/fleet/device/${encodeURIComponent(params.deviceType)}/${encodeURIComponent(params.deviceId)}/summary`,
  });
  items.push({
    desc: "detailsItem",
    label: t`Details`,
    key: 1,
    icon: ViewListIcon,
    url: `/fleet/device/${encodeURIComponent(params.deviceType)}/${encodeURIComponent(params.deviceId)}/details`,
  });
  items.push({
    desc: "alarmsItem",
    label: t`Alarms`,
    key: 2,
    icon: AlarmIcon,
    url: `/fleet/device/${encodeURIComponent(params.deviceType)}/${encodeURIComponent(params.deviceId)}/alarms`,
  });

  return items;
};

export interface DeviceData {
  id: string;
  type: string;
}

export const deviceToLinkElement = (device: Device): string => {
  return encodeURIComponent(device.type) + "/" + encodeURIComponent(device.id);
};

export default getNavigationItems;

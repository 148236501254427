import FleetOverviewIcon from "@material-ui/icons/HomeWorkTwoTone";
import ImprintIcon from "@material-ui/icons/ContactMailTwoTone";
import SettingsIcon from "@material-ui/icons/Settings";
import { NavigationItems } from "./navigationItem";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { t } from "@lingui/macro"

const navigationStructure: NavigationItems = {
  items: [
    {
      desc: "fleetItem",
      label: t`Fleet`,
      key: 0,
      icon: FleetOverviewIcon,
      url: "/fleet",
    },
    {
      desc: "settingsItem",
      label: t`Settings`,
      key: 1,
      icon: SettingsIcon,
      url: "/settings",
    },
    {
      desc: "imprintItem",
      label: t`Imprint`,
      key: 2,
      icon: ImprintIcon,
      url: "/imprint",
    },
    {
      desc: "signOutItem",
      label: t`Sign out`,
      key: 3,
      icon: ExitToAppIcon,
      url: "/signout",
    },
  ],
};

export default navigationStructure;

import { useReducer, useEffect } from "react";
import { getAtaByDevice } from "../../graphql/queries";
import { onUpdateAtaForDevice } from "../../graphql/subscriptions";
import { GetAtaByDeviceQuery, DeviceType, OnUpdateAtaForDeviceSubscription } from "../../API";
import { graphqlOperation, API } from "aws-amplify";
import Observable from "zen-observable";
import { Ata } from "../extendedApiTypes";

export interface UseAtaState {
  loading: boolean;
  error: any;
  ata: Ata | null;
}

type Action =
  | { type: "initialSet"; data: GetAtaByDeviceQuery }
  | { type: "refresh"; data: OnUpdateAtaForDeviceSubscription }
  | { type: "error"; error: any };

const initialState: UseAtaState = { loading: true, error: "", ata: null };

const ataReducer = (state: UseAtaState, action: Action) : UseAtaState => {
  switch (action.type) {
    case "initialSet":
      return { loading: false, error: "", ata: getAtaEntriesFromQuery(action.data) };
    case "refresh":
      return { loading: false, error: "", ata: getAtaEntriesFromSubscription(action.data) };
    case "error":
      return { loading: false, error: action.error, ata: null };
    default:
      throw new Error();
  }
};

const getAtaEntriesFromQuery = (data: GetAtaByDeviceQuery): Ata | null => {
  if (data.getAtaByDevice !== null && data.getAtaByDevice.items !== null) {
    return data.getAtaByDevice;
  } else {
    return null;
  }
};

const getAtaEntriesFromSubscription = (data: OnUpdateAtaForDeviceSubscription): Ata | null => {
  if (data.onUpdateAtaForDevice !== null && data.onUpdateAtaForDevice.items !== null) {
    return data.onUpdateAtaForDevice;
  } else {
    return null;
  }
};

export const useAta = (deviceId: string, deviceType: DeviceType): UseAtaState => {
  const [state, dispatch] = useReducer(ataReducer, initialState);

  useEffect(() => {
    const fetchAlarms = async () => {
      try {
        const { data } = (await API.graphql(
          graphqlOperation(getAtaByDevice, {
            deviceId: deviceId,
            deviceType: deviceType,
          })
        )) as {
          data: GetAtaByDeviceQuery;
        };

        dispatch({ type: "initialSet", data: data });
      } catch (error) {
        console.log(error);
        dispatch({ type: "error", error: error });
      }
    };

    fetchAlarms();
  }, [deviceId, deviceType]);

  useEffect(() => {
    let unsubscribe;
    const subscription = API.graphql(
      graphqlOperation(onUpdateAtaForDevice, {
        deviceId: deviceId,
        deviceType: deviceType,
      })
    );
    if (subscription instanceof Observable) {
      const sub = subscription.subscribe({
        next: (payload) => {
          const {
            value: { data },
          }: {
            value: { data: OnUpdateAtaForDeviceSubscription };
          } = payload;

          dispatch({ type: "refresh", data: data });
        },
      });
      unsubscribe = () => {
        sub.unsubscribe();
      };
    }

    return unsubscribe;
  }, [deviceId, deviceType]);

  return state;
};

// Polyfill required to handle older browsers which do not support the fetch API natively,
// it must be imported before Amplify and React to be used internally.
import "whatwg-fetch";

import React from "react";
import ReactDOM from "react-dom";
import App from "./components/app";
import WithServiceWorker from "./components/hoc/WithServiceWorker";

import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import "@aws-amplify/ui/dist/style.css";
import { cognitoUserPoolConfig, appSyncConfig } from "./app-config";

import "./index.css";

const AWS_REGION = "eu-central-1";

Amplify.configure({
  Auth: {
    region: AWS_REGION,
    userPoolId: cognitoUserPoolConfig.userPoolId,
    userPoolWebClientId: cognitoUserPoolConfig.userPoolWebClientId,   
  },
  aws_appsync_graphqlEndpoint: appSyncConfig.aws_appsync_graphqlEndpoint,
  aws_appsync_region: AWS_REGION,
  aws_appsync_authenticationType: appSyncConfig.aws_appsync_authenticationType,  
});

// Do not change configuration sequence as local settings should override
// static ones for development purposes. The aws-exports file must not be
// committed to avoid configuration issues.
Amplify.configure(awsconfig);

ReactDOM.render(
  <WithServiceWorker>
    <App />
  </WithServiceWorker>,
  document.getElementById("root")
);

import { Store } from "use-global-hook";
import { GlobalActions, GlobalState, DevicesState } from "../globalStoreTypes";
import { getDevicesByUser } from "../../graphql/queries";
import { GetDevicesByUserQuery } from "../../API";
import { graphqlOperation, API } from "aws-amplify";

const NO_DEVICES_FOR_USER_ERROR =
  "Not Authorized to access getDevicesByUser on type Query";

const queryDevices = async (store: Store<GlobalState, GlobalActions>, username: string) : Promise<void> => {
  // TODO Idee: GetDevicesByUserQueryVariables nutzen...

  store.setState({ ...store.state, devicesState: DevicesState.LOADING });

  try {
    const { data } = (await API.graphql(
      graphqlOperation(getDevicesByUser, { userId: username })
    )) as {
      data: GetDevicesByUserQuery;
    };

    if (data.getDevicesByUser) {
      store.setState({
        ...store.state,
        devicesState: DevicesState.INITIALIZED,
        devices: data.getDevicesByUser.map((device) => ({ ...device }))
      });
    } else {
      // Should not happen
      console.log("data.getDevicesByUser === null");
      store.setState({
        ...store.state,
        devicesState: DevicesState.ERROR,
        devices: []
      });
    }
  } catch (error) {
    if (JSON.stringify(error).includes(NO_DEVICES_FOR_USER_ERROR)) {
      store.setState({
        ...store.state,
        devicesState: DevicesState.NO_DEVICES_FOR_USER,
        devices: []
      });
    } else {
      console.log(error);
      store.setState({
        ...store.state,
        devicesState: DevicesState.ERROR,
        devices: []
      });
    }
  }
};

export default queryDevices;

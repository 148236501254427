import { determineCurrentLanguage } from "./i18nUtils";

// Current language has to be determined explicitely as the global language context
// runs after the initalization of the Amplify auth component so that it cannot be
// used to retrieve the active language.
const locale = determineCurrentLanguage();

// Localization of first and familiy name fields must be done hard coded as the 
// default i18n mechanism of the Amplify auth component does not support custom fields. 
const signUpConfig = [
  {
    label: "Email",
    required: true,
    type: "email",
  },
  {
    label: locale === "en" ? "First Name" : "Vorname",
    required: true,
    type: "given_name",
    placeholder: locale === "en" ? "Enter your first name" : "Vorname eingeben",
  },
  {
    label: locale === "en" ? "Family Name" : "Nachname",
    required: true,
    type: "family_name",
    placeholder: locale === "en" ? "Enter your family name" : "Nachname eingeben",
  },
  {
    label: "Password",
    required: true,
    type: "password",
  },
];

export { signUpConfig };

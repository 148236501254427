import { DeviceType } from "../../API";
import { Message } from "../extendedApiTypes";

export interface EnrichedDatapoints {
  deviceId: string;
  deviceType: DeviceType;
  product: string | null;
  enrichedDatapoints: EnrichedDatapoint[];
  timestamp: string;
}

export type EnrichedDatapoint = EnrichedAnalogDatapoint | EnrichedDiscreteDatapoint;

export type EnrichedAnalogDatapoint = {
  id: string;
  appIdDesc: string;
  unit: string | null;
  text: Message[];
  analogValue: number;
};

export type EnrichedDiscreteDatapoint = {
  id: string;
  appIdDesc: string;
  text: Message[];
  discreteValue: Message[];
};

export function isAnalog(enrichedDatapoint: EnrichedDatapoint): enrichedDatapoint is EnrichedAnalogDatapoint {
  return (enrichedDatapoint as EnrichedAnalogDatapoint).analogValue !== undefined;
}

export function isDiscrete(enrichedDatapoint: EnrichedDatapoint): enrichedDatapoint is EnrichedDiscreteDatapoint {
  return (enrichedDatapoint as EnrichedDiscreteDatapoint).discreteValue !== undefined;
}
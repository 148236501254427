import * as React from "react";
import DeviceCard from "../../deviceCard";
import useGlobal from "../../../store";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import sortBy from "lodash/sortBy";

import { Trans } from "@lingui/macro";
import { getStatusMessage } from "../routeUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      marginTop: 5,
      marginBottom: 10,
      color: theme.palette.primary.main,
    },
  })
);

interface IFleetOverviewProps {}

const FleetOverview: React.FunctionComponent<IFleetOverviewProps> = (props) => {
  const classes = useStyles();
  const [{ devices, devicesState }] = useGlobal();

  const devicesSortedByEngineName = sortBy(devices, ["engine"]);
  const statusMessage = getStatusMessage(devicesState);

  return (
    <div className="fleetOverview">
      <Typography variant="h4" className={classes.title}>
        <Trans>Fleet Overview</Trans>
      </Typography>
      {statusMessage && <Typography variant="body2">{<Trans id={statusMessage} />}</Typography>}
      <Grid container spacing={2}>
        {devicesSortedByEngineName.map((device) => (
          <Grid item xs={12} sm={6} lg={4} key={device.id}>
            <DeviceCard device={device} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default FleetOverview;

import React from "react";
import { Device } from "../../../../../store/extendedApiTypes";
import { DetailValue, useDeviceDetails } from "../../useDeviceData";
import { Grid, Typography } from "@material-ui/core";
import DeviceDataSection from "../../commonComponents/deviceDataSection/DeviceDataSection";
import {
  getTagTranslationsForProduct,
  getSectionOrderForProduct,
} from "../../../../../store/tagTranslations";
import { Trans } from "@lingui/macro";

const ROOT_SCOPE: string = "DetailsPage";

export interface IDeviceDetailsProps {
  device: Device;
}

const CustomDeviceDetails: React.FunctionComponent<IDeviceDetailsProps> = ({ device }) => {
  const { getLiveValuesForScope, getLiveDetailsLoadingStatus } = useDeviceDetails(device);
  const liveDetailsLoadingStatus: string | undefined = getLiveDetailsLoadingStatus();

  const createGridSectionsByGroup = (liveDetailsLoadingStatus: string | undefined): JSX.Element => {
    interface JsxElementWithSortingIndex {
      element: JSX.Element;
      index: number;
      numOfRows: number;
    }

    const unorderedSections = new Array<JsxElementWithSortingIndex>();
    const titlesWithLiveValues: Map<string, DetailValue[]> = getLiveValuesForScope(ROOT_SCOPE, 0);
    const SECTION_ORDER = getSectionOrderForProduct(device.type, device.appName);

    titlesWithLiveValues.forEach((values: DetailValue[], title: string) => {
      const translatedTitle = getTagTranslationsForProduct(device.type, device.appName).get(title);
      unorderedSections.push({
        element: (
          <Grid item xs={12} sm={12} lg={12} key={SECTION_ORDER.indexOf(title)}>
            <DeviceDataSection values={values} sectionHeader={translatedTitle}></DeviceDataSection>
          </Grid>
        ),
        index: SECTION_ORDER.indexOf(title),
        numOfRows: values.length,
      });
    });

    const sortedSections = unorderedSections.sort(
      (a: JsxElementWithSortingIndex, b: JsxElementWithSortingIndex) => a.index - b.index
    );

    let firstColumnSections = new Array<JSX.Element>();
    const secondColumnSections = new Array<JSX.Element>();

    if (sortedSections.length <= 1) {
      firstColumnSections = sortedSections.map((item) => item.element);
    } else {
      const totalRows = sortedSections
        .map((item) => item.numOfRows)
        .reduce((accumulator, currentValue) => accumulator + currentValue);

      let counter = 0;
      sortedSections.forEach((item: JsxElementWithSortingIndex) => {
        counter += item.numOfRows;
        if (item.index === 0 || counter <= Math.ceil(totalRows / 2)) {
          firstColumnSections.push(item.element);
        } else {
          secondColumnSections.push(item.element);
        }
      });
    }

    return (
      <Grid container spacing={3}>
        <Grid container item xs={12} sm={6} lg={6} style={{ alignContent: "baseline" }}>
          {firstColumnSections}
        </Grid>
        <Grid container item xs={12} sm={6} lg={6} style={{ alignContent: "baseline" }}>
          {secondColumnSections}
        </Grid>
      </Grid>
    );
  };

  const orderedLiveSections: JSX.Element = createGridSectionsByGroup(liveDetailsLoadingStatus);

  return (
    <div>
      {liveDetailsLoadingStatus && (
        <Typography variant="body1" paragraph>
          <Trans id={liveDetailsLoadingStatus} />
        </Typography>
      )}
      {orderedLiveSections}
    </div>
  );
};

export default CustomDeviceDetails;

import * as React from "react";

import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { Trans, t } from '@lingui/macro';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    table: {
      width: "100%",
      maxWidth: "500px",
    },
    marginBottom: {
      marginBottom: "30px !important"
    },
    title: {
      marginTop: 5,
      marginBottom: 10,
      color: theme.palette.primary.main
    }
  })
);

export default function Imprint() {
  const classes = useStyles();

  function createData(leftColumn: string, rightColumn: string) {
    return {leftColumn: leftColumn, rightColumn: rightColumn };
  }

  const rows = [
    createData(t`Fon. (0 70 71) 97 35-0`, t`international +49 7071 9735-0`),
    createData(t`Fax (0 70 71) 97 35-55`, t`international +49 7071 9735-55`),
    createData(t`E-Mail`, t`avat(at)avat.de`),
    createData(t`Web`, t`www.avat.de`),
    createData(t`Company headquarters`, t`Tübingen`),
    createData(t`Commercial Register`, t`HRB 381463`),
    createData(t`Court`, t`Amtsgericht Stuttgart`),
    createData(t`Managing Director`, t`Dipl.-Ing. Frank Ganssloser`),
    createData(t`Responsible for content`, t`Dittmar Wright`),
    createData(t`VAT No.`, t`DE160330421`)
  ];

  return (
    <div className={classes.root}>
      <Typography variant="h4" gutterBottom className={classes.title}>
        <Trans>Imprint</Trans>
      </Typography>

      <Typography variant="body1" gutterBottom>
        AVAT Automation GmbH
      </Typography>
      <Typography variant="body1" gutterBottom>
        Derendinger Straße 40
      </Typography>
      <Typography variant="body1" gutterBottom className={classes.marginBottom}>
        72072 Tübingen - <Trans>Germany</Trans>
      </Typography>
      <TableContainer className={classes.marginBottom}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <Trans id={row.leftColumn} />
                </TableCell>
                <TableCell align="right"><Trans id={row.rightColumn} /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h6"><Trans>Disclaimer</Trans></Typography>
      <Typography variant="body1" gutterBottom>
        <Trans> The content of our website has been compiled with meticulous care and to the best of our
        knowledge. However, we cannot assume any liability for the up-to-dateness, completeness or
        accuracy of any of the pages.</Trans>
      </Typography>
    </div>
  );
}

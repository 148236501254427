import React from "react";
import { Link, useLocation } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import navigationStructure from "../mainNavigationStructure";
import NavigationItem from "../navigationItem";
import avatLogo from "../../../images/AVAT_Logo_Standard_4C_positiv.svg";
import useLocationInMainNavigation from "../useLocationInMainNavigation";
import { Trans } from "@lingui/macro";

let drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      flex: 1,
    },
    logo: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 40,
      marginBottom: 20,
    },
    devider: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
    },
    linkListItemText: {
      color: theme.palette.secondary.main,
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.primary.main,
        textDecoration: "underline",
      },
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    toolbarGap: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    urlMatch: {
      backgroundColor: theme.palette.primary.main + " !important",
      color: "white !important",
    },
  })
);

interface INavigationDrawerProps {
  isOpen: boolean;
  width: number;
  toggle(): void;
}

const NavigationDrawer: React.FunctionComponent<INavigationDrawerProps> = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const locationInMainNavigation = useLocationInMainNavigation(location);

  if (props.width) {
    drawerWidth = props.width;
  }

  function resolveStyleClass(
    theLocationinMainNavigation: NavigationItem | null,
    itemUrl: string
  ): string {
    if (theLocationinMainNavigation === null) {
      return "";
    }
    if (theLocationinMainNavigation.url === itemUrl) {
      return classes["urlMatch"]; // see style definition
    }
    return "";
  }

  const menuItems = navigationStructure.items.map((item: NavigationItem) => (
    <ListItem
      button
      component={Link}
      to={item.url}
      key={item.key}
      onClick={props.toggle}
      className={resolveStyleClass(locationInMainNavigation, item.url)}
    >
      <ListItemIcon>{<item.icon />}</ListItemIcon>
      <ListItemText
        primary={<Trans id={item.label}/>}
        className={`${classes.linkListItemText} ${resolveStyleClass(
          locationInMainNavigation,
          item.url
        )}`}
      />
    </ListItem>
  ));

  const drawerContent = (
    <div>
      <img alt="AVAT" src={avatLogo} width={160} className={classes.logo} />
      <Divider variant="middle" className={classes.devider} />
      <List>{menuItems}</List>
      <Divider variant="middle" className={classes.devider} />
    </div>
  );

  return (
    <nav aria-label="navigation">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={props.isOpen}
          onClose={props.toggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="persistent"
          open={props.isOpen}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default NavigationDrawer;

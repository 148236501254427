/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type AddDatapointsInput = {
  deviceId: string,
  deviceType: DeviceType,
  datapoints: Array< DatapointInput >,
  timestamp: string,
  readAccess: Array< string >,
};

export enum DeviceType {
  TESTDUMMY = "TESTDUMMY",
  OPENECS = "OPENECS",
  SE2OPTIMIZER = "SE2OPTIMIZER",
  TEM = "TEM",
  SEVA = "SEVA",
}


export type DatapointInput = {
  id: string,
  analogValue?: number | null,
  discreteValue?: Array< MessageInput > | null,
  groups?: Array< string > | null,
};

export type MessageInput = {
  langCode: string,
  text: string,
};

export type DeviceIdAndTypeInput = {
  deviceId: string,
  deviceType: DeviceType,
};

export type UpdateDataModelInput = {
  deviceId: string,
  deviceType: DeviceType,
  product?: string | null,
  datapointModels: Array< DatapointModelInput >,
  readAccess: Array< string >,
  dataVisuModelId?: string | null,
};

export type DatapointModelInput = {
  id: string,
  appIdDesc: string,
  type: DatapointType,
  unit?: string | null,
  text: Array< MessageInput >,
  tags?: Array< string | null > | null,
};

export enum DatapointType {
  DISCRETE_MEASURAND = "DISCRETE_MEASURAND",
  ANALOG_MEASURAND = "ANALOG_MEASURAND",
  MEASURAND = "MEASURAND",
}


export type UpdateAtaInput = {
  deviceId: string,
  deviceType: DeviceType,
  timestamp: string,
  ataEntries: Array< AtaEntryInput | null >,
  readAccess: Array< string >,
};

export type AtaEntryInput = {
  id: string,
  acknowledged: boolean,
  message: Array< MessageInput >,
  messageType: string,
  timestamp: number,
};

export type UpdateAtaConfigInput = {
  deviceType: DeviceType,
  deviceId: string,
  ataNoteType: AtaNotificationType,
};

export enum AtaNotificationType {
  NONE = "NONE",
  FAILURE = "FAILURE",
  ALARM = "ALARM",
}


export type AddDeviceInput = {
  userId: string,
  deviceId: string,
  deviceType: DeviceType,
  appName: string,
  engine?: string | null,
  plant?: string | null,
  timeZone?: string | null,
  ataNoteType?: AtaNotificationType | null,
};

export type DeleteDeviceInput = {
  userId: string,
  deviceId: string,
  deviceType: DeviceType,
};

export type AddUserInput = {
  userId: string,
  preferedLocale: string,
};

export type DeleteUserInput = {
  userId: string,
};

export type UpdateUserInput = {
  preferedLocale: string,
};

export type AddDatapointsForDeviceMutationVariables = {
  input: AddDatapointsInput,
};

export type AddDatapointsForDeviceMutation = {
  addDatapointsForDevice:  {
    __typename: "Datapoints",
    deviceId: string,
    deviceType: DeviceType,
    datapoints:  Array< {
      __typename: "Datapoint",
      id: string,
      analogValue: number | null,
      discreteValue:  Array< {
        __typename: "Message",
        langCode: string,
        text: string,
      } > | null,
    } >,
    timestamp: string,
  },
};

export type DeleteDatapointsForDeviceMutationVariables = {
  input: DeviceIdAndTypeInput,
};

export type DeleteDatapointsForDeviceMutation = {
  deleteDatapointsForDevice:  {
    __typename: "Datapoints",
    deviceId: string,
    deviceType: DeviceType,
    datapoints:  Array< {
      __typename: "Datapoint",
      id: string,
      analogValue: number | null,
      discreteValue:  Array< {
        __typename: "Message",
        langCode: string,
        text: string,
      } > | null,
    } >,
    timestamp: string,
  } | null,
};

export type UpdateDataModelForDeviceMutationVariables = {
  input: UpdateDataModelInput,
};

export type UpdateDataModelForDeviceMutation = {
  updateDataModelForDevice:  {
    __typename: "DeviceDataModel",
    deviceId: string,
    deviceType: DeviceType,
    product: string | null,
    datapointModels:  Array< {
      __typename: "DatapointModel",
      id: string,
      appIdDesc: string,
      type: DatapointType,
      unit: string | null,
      text:  Array< {
        __typename: "Message",
        langCode: string,
        text: string,
      } >,
      tags: Array< string | null > | null,
    } >,
  },
};

export type DeleteDataModelForDeviceMutationVariables = {
  input: DeviceIdAndTypeInput,
};

export type DeleteDataModelForDeviceMutation = {
  deleteDataModelForDevice:  {
    __typename: "DeviceDataModel",
    deviceId: string,
    deviceType: DeviceType,
    product: string | null,
    datapointModels:  Array< {
      __typename: "DatapointModel",
      id: string,
      appIdDesc: string,
      type: DatapointType,
      unit: string | null,
      text:  Array< {
        __typename: "Message",
        langCode: string,
        text: string,
      } >,
      tags: Array< string | null > | null,
    } >,
  } | null,
};

export type UpdateAtaForDeviceMutationVariables = {
  input: UpdateAtaInput,
};

export type UpdateAtaForDeviceMutation = {
  updateAtaForDevice:  {
    __typename: "Ata",
    deviceId: string,
    deviceType: DeviceType,
    items:  Array< {
      __typename: "AtaEntry",
      id: string,
      acknowledged: boolean,
      message:  Array< {
        __typename: "Message",
        langCode: string,
        text: string,
      } >,
      messageType: string,
      timestamp: number,
    } >,
    timestamp: string,
  },
};

export type UpdateAtaConfigOfUserForDeviceMutationVariables = {
  input?: UpdateAtaConfigInput | null,
};

export type UpdateAtaConfigOfUserForDeviceMutation = {
  updateAtaConfigOfUserForDevice: AtaNotificationType,
};

export type DeleteAtaForDeviceMutationVariables = {
  input: DeviceIdAndTypeInput,
};

export type DeleteAtaForDeviceMutation = {
  deleteAtaForDevice:  {
    __typename: "Ata",
    deviceId: string,
    deviceType: DeviceType,
    items:  Array< {
      __typename: "AtaEntry",
      id: string,
      acknowledged: boolean,
      message:  Array< {
        __typename: "Message",
        langCode: string,
        text: string,
      } >,
      messageType: string,
      timestamp: number,
    } >,
    timestamp: string,
  } | null,
};

export type AddDeviceForUserMutationVariables = {
  input: AddDeviceInput,
};

export type AddDeviceForUserMutation = {
  addDeviceForUser:  {
    __typename: "Device",
    id: string,
    type: DeviceType,
    appName: string,
    engine: string | null,
    plant: string | null,
    timeZone: string | null,
    ataNoteType: AtaNotificationType,
    dataModel:  {
      __typename: "DeviceDataModel",
      deviceId: string,
      deviceType: DeviceType,
      product: string | null,
      datapointModels:  Array< {
        __typename: "DatapointModel",
        id: string,
        appIdDesc: string,
        type: DatapointType,
        unit: string | null,
        text:  Array< {
          __typename: "Message",
          langCode: string,
          text: string,
        } >,
        tags: Array< string | null > | null,
      } >,
    } | null,
  },
};

export type DeleteDeviceForUserMutationVariables = {
  input: DeleteDeviceInput,
};

export type DeleteDeviceForUserMutation = {
  deleteDeviceForUser:  {
    __typename: "Device",
    id: string,
    type: DeviceType,
    appName: string,
    engine: string | null,
    plant: string | null,
    timeZone: string | null,
    ataNoteType: AtaNotificationType,
    dataModel:  {
      __typename: "DeviceDataModel",
      deviceId: string,
      deviceType: DeviceType,
      product: string | null,
      datapointModels:  Array< {
        __typename: "DatapointModel",
        id: string,
        appIdDesc: string,
        type: DatapointType,
        unit: string | null,
        text:  Array< {
          __typename: "Message",
          langCode: string,
          text: string,
        } >,
        tags: Array< string | null > | null,
      } >,
    } | null,
  } | null,
};

export type AddUserMutationVariables = {
  input: AddUserInput,
};

export type AddUserMutation = {
  addUser:  {
    __typename: "User",
    id: string,
    preferedLocale: string,
  },
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
};

export type DeleteUserMutation = {
  deleteUser:  {
    __typename: "User",
    id: string,
    preferedLocale: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
};

export type UpdateUserMutation = {
  updateUser:  {
    __typename: "User",
    id: string,
    preferedLocale: string,
  },
};

export type GetUserByIdQuery = {
  getUserById:  {
    __typename: "User",
    id: string,
    preferedLocale: string,
  },
};

export type GetDevicesByUserQuery = {
  getDevicesByUser:  Array< {
    __typename: "Device",
    id: string,
    type: DeviceType,
    appName: string,
    engine: string | null,
    plant: string | null,
    timeZone: string | null,
    ataNoteType: AtaNotificationType,
    dataModel:  {
      __typename: "DeviceDataModel",
      deviceId: string,
      deviceType: DeviceType,
      product: string | null,
      datapointModels:  Array< {
        __typename: "DatapointModel",
        id: string,
        appIdDesc: string,
        type: DatapointType,
        unit: string | null,
        text:  Array< {
          __typename: "Message",
          langCode: string,
          text: string,
        } >,
        tags: Array< string | null > | null,
      } >,
    } | null,
  } > | null,
};

export type GetAtaByDeviceQueryVariables = {
  deviceId: string,
  deviceType: DeviceType,
};

export type GetAtaByDeviceQuery = {
  getAtaByDevice:  {
    __typename: "Ata",
    deviceId: string,
    deviceType: DeviceType,
    items:  Array< {
      __typename: "AtaEntry",
      id: string,
      acknowledged: boolean,
      message:  Array< {
        __typename: "Message",
        langCode: string,
        text: string,
      } >,
      messageType: string,
      timestamp: number,
    } >,
    timestamp: string,
  } | null,
};

export type GetDatapointsByDeviceQueryVariables = {
  deviceId: string,
  deviceType: DeviceType,
};

export type GetDatapointsByDeviceQuery = {
  getDatapointsByDevice:  {
    __typename: "Datapoints",
    deviceId: string,
    deviceType: DeviceType,
    datapoints:  Array< {
      __typename: "Datapoint",
      id: string,
      analogValue: number | null,
      discreteValue:  Array< {
        __typename: "Message",
        langCode: string,
        text: string,
      } > | null,
    } >,
    timestamp: string,
  } | null,
};

export type OnUpdateAtaForDeviceSubscriptionVariables = {
  deviceId: string,
  deviceType: DeviceType,
};

export type OnUpdateAtaForDeviceSubscription = {
  onUpdateAtaForDevice:  {
    __typename: "Ata",
    deviceId: string,
    deviceType: DeviceType,
    items:  Array< {
      __typename: "AtaEntry",
      id: string,
      acknowledged: boolean,
      message:  Array< {
        __typename: "Message",
        langCode: string,
        text: string,
      } >,
      messageType: string,
      timestamp: number,
    } >,
    timestamp: string,
  } | null,
};

export type OnAddDatapointsForDeviceSubscriptionVariables = {
  deviceId: string,
  deviceType: DeviceType,
};

export type OnAddDatapointsForDeviceSubscription = {
  onAddDatapointsForDevice:  {
    __typename: "Datapoints",
    deviceId: string,
    deviceType: DeviceType,
    datapoints:  Array< {
      __typename: "Datapoint",
      id: string,
      analogValue: number | null,
      discreteValue:  Array< {
        __typename: "Message",
        langCode: string,
        text: string,
      } > | null,
    } >,
    timestamp: string,
  } | null,
};

import { Device, DeviceDataModel, Tag, UNTAGGED } from "../extendedApiTypes";
import {
  EnrichedDatapoint,
  EnrichedDatapoints,
  EnrichedAnalogDatapoint,
  EnrichedDiscreteDatapoint,
  isAnalog,
  isDiscrete,
} from "./customDatapointTypes";
import { useEnrichedDatapoints } from "./useEnrichedDatapoints";


interface UseDatapointsState {
  loading: boolean;
  error: any;
  enrichedDatapoints: EnrichedDatapoints | null;
  analogDatapointsById: Map<String, EnrichedAnalogDatapoint>;
  discreteDatapointsById: Map<String, EnrichedDiscreteDatapoint>;
  groupsByTag: Map<Tag, EnrichedDatapoint[]>; // given a tag, determine all datapoints within that group
}

export const useDatapointsForDisplay = (device: Device): UseDatapointsState => {
  const analogDatapointsById = new Map<String, EnrichedAnalogDatapoint>();
  const discreteDatapointsById = new Map<String, EnrichedDiscreteDatapoint>();
  const groupsByTag = new Map<string, EnrichedDatapoint[]>();

  const { loading, error, enrichedDatapoints } = useEnrichedDatapoints(device);
  if (!enrichedDatapoints) {
    return { loading, error, enrichedDatapoints, analogDatapointsById, discreteDatapointsById, groupsByTag };
  }


  // setup mappings
  const tagsFromModel: Map<String, Tag[]> = extractTagsFromModel(device.dataModel);

  enrichedDatapoints.enrichedDatapoints.forEach((datapoint: EnrichedDatapoint) => {
    // analog and discrete mappings
    if (isAnalog(datapoint)) {
      analogDatapointsById.set(datapoint.id, datapoint);
    } else if (isDiscrete(datapoint)) {
      discreteDatapointsById.set(datapoint.id, datapoint);
    }

    // tag mappings
    const currentTags = tagsFromModel.get(datapoint.id);
    if (currentTags) {
      currentTags.forEach((currentTag: Tag) => {
        addToTagsList(groupsByTag, currentTag, datapoint, currentTags);
      });
    } else {
      // something must be wrong with extractTagsFromModel
      throw new Error(`Error processing datapoint tags. Did not find tags for ${datapoint.id}.`)
    }
  }
  );

  return { loading, error, enrichedDatapoints, analogDatapointsById, discreteDatapointsById, groupsByTag };
};

/**
 * Returns a mapping from datapointId to all of its tags for one device.
 * Adds the tag "UNTAGGED" if none is given.
 */
export const extractTagsFromModel = (dataModel: DeviceDataModel | null): Map<String, Tag[]> => {
  const tagsFromModel: Map<String, Tag[]> = new Map<String, Tag[]>();
  if (dataModel) {
    dataModel.datapointModels.forEach(dataPointModel => {
      if (dataPointModel.tags && dataPointModel.tags.length !== 0) {
        // if there are no tags, the graphql operation for dataPoints yields [null]. This is what we define as UNTAGGED
        if (dataPointModel.tags.length === 1 && dataPointModel.tags[0] === null) {
          tagsFromModel.set(dataPointModel.id, [UNTAGGED]);
        } else {
          tagsFromModel.set(dataPointModel.id, dataPointModel.tags as Tag[]);
        }
      } else {
        tagsFromModel.set(dataPointModel.id, [UNTAGGED]);
      }
    });
  }
  return tagsFromModel;
}

export const addToTagsList = (groupsByTag: Map<Tag, EnrichedDatapoint[]>, currentTag: Tag, datapoint: EnrichedDatapoint, verificationTags: Tag[]): void => {
  const dataPointList: EnrichedDatapoint[] | undefined = groupsByTag.get(currentTag);

  if (!verificationTags.includes(currentTag)) {
    throw new Error("Inconsistency between datapoint Model and tags: Detected attempt to add a tag to the groupsByTag mapping which was not included in the data model");
  }
  
  if (dataPointList) {
    dataPointList.push(datapoint);
  } else {
    groupsByTag.set(currentTag, new Array<EnrichedDatapoint>(datapoint));
  }
}


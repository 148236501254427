import * as React from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import FleetOverview from "../routes/fleetOverview";
import Settings from "../routes/settingsMain";
import Container from "@material-ui/core/Container";
import DeviceMain from "../routes/device/deviceMain";
import Imprint from "../routes/imprint";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      marginTop: 20,
    },
  })
);

interface IMainProps {}

const Main: React.FunctionComponent<IMainProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const handleSignOut = () => {
    Auth.signOut()
      .then(() => {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("jwtToken");
        history.push("/");
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className={classes.main}>
      <Container maxWidth="lg">
        <Switch>
          <Redirect exact from="/" to="/fleet" />
          <Route exact path="/fleet" component={FleetOverview} />
          <Route path="/fleet/device/:deviceType/:deviceId" component={DeviceMain} />
          <Route path="/settings" component={Settings} />
          <Route path="/imprint" component={Imprint} />
          <Route
            path="/signout"
            render={() => {
              handleSignOut();
              return <div></div>;
            }}
          />
        </Switch>
      </Container>
    </div>
  );
};

export default Main;

import { Store } from "use-global-hook";
import { GlobalActions, GlobalState } from "../globalStoreTypes";
import { getUserById } from "../../graphql/queries";
import { GetUserByIdQuery } from "../../API";
import { graphqlOperation, API } from "aws-amplify";
import { FALLBACK_LANGUAGE } from "../../components/auth/i18nUtils";

const queryUserData = async (store: Store<GlobalState, GlobalActions>): Promise<void> => {
  try {
    const { data } = (await API.graphql(graphqlOperation(getUserById))) as {
      data: GetUserByIdQuery;
    };

    store.setState({
      ...store.state,
      userData: { preferedLocale: data.getUserById.preferedLocale },
    });
  } catch (error) {
    console.log(error);
    store.setState({
      ...store.state,
      userData: { preferedLocale: FALLBACK_LANGUAGE },
    });
  }
};

export default queryUserData;

import React  from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, Typography, SvgIconTypeMap, SvgIcon } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    icon: {
      color: theme.palette.secondary.main
    }
  })
);

interface ILabeledIconRendererProps {
  icon?: OverridableComponent<SvgIconTypeMap>;
  label: string;
  styles?: Record<string, string>;
}

const LabeledIconRenderer: React.FunctionComponent<ILabeledIconRendererProps> = ({icon, label, styles}) => {
  const classes = useStyles();

  const Icon: OverridableComponent<SvgIconTypeMap> = icon ? icon : SvgIcon;

  return (
    <div className={styles && styles.root? styles.root : classes.root}>
      <Grid container spacing={5}>
        <Grid item xs={3}>
          <Icon className={classes.icon}/>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body2" noWrap>{label}</Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default LabeledIconRenderer;
import * as React from 'react';
import Typography from "@material-ui/core/Typography";
import { Switch, Route, Redirect } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import SettingsTabNavigation from "../../navigation/settingsTabNavigation";
import SettingsBreadcrumbs from "../../navigation/settingsBreadcrumbs";
import SettingsBottomNavigation from "../../navigation/settingsBottomNavigation";
import SettingsAlarms from "../settingsAlarms";
import { Trans } from '@lingui/macro';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: 5,
      marginBottom: 10,
      color: theme.palette.primary.main
    }
  })
);

interface ISettingsProps {
}

const SettingsMain: React.FunctionComponent<ISettingsProps> = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Hidden smUp>
        <SettingsBreadcrumbs />
      </Hidden>
      <Typography variant="h4" className={classes.title}>
        <Trans>Settings</Trans>
      </Typography>
      <Hidden xsDown>
        <SettingsTabNavigation />
      </Hidden>
      <div>
        <Switch>
          <Route path="/settings/alarms" component={SettingsAlarms} />
          {/* <Route path="/settings/general" component={SettingsGeneral} /> */}
          <Redirect from="/settings" to="/settings/alarms" />
        </Switch>
      </div>
      <Hidden smUp>
        <SettingsBottomNavigation />
      </Hidden>
    </div>
  );
};

export default SettingsMain;

import { DeviceType, AtaNotificationType, DatapointType } from "../API";
import { t } from "@lingui/macro";

export type Tag = string;
export const UNTAGGED : Tag = "#UNTAGGED";

//TODO: id eigener Typ?

export interface Device {
  id: string;
  type: DeviceType;
  appName: string;
  engine: string | null;
  plant: string | null;
  timeZone: string | null;
  ataNoteType: AtaNotificationType;
  dataModel: DeviceDataModel | null;
}

export interface DeviceDataModel {
  deviceId: string;
  deviceType: DeviceType;
  product: string | null;
  datapointModels: DatapointModel[];
}

export interface DatapointModel {
  id: string;
  appIdDesc: string;
  type: DatapointType;
  unit: string | null;
  text: Message[];
  tags: (Tag | null)[] | null;
}

export interface Datapoints {
  deviceId: string;
  deviceType: DeviceType;
  datapoints: Datapoint[];
  timestamp: string;
}

export interface Datapoint {
  id: string;
  analogValue: number | null;
  discreteValue: Message[] | null;
}

export interface Ata {
  deviceId: string;
  deviceType: DeviceType;
  items: AtaEntry[];
  timestamp: string;
}

export interface AtaEntry {
  id: string;
  acknowledged: boolean;
  messageType: string;
  message: Message[];
  timestamp: number;
}

export interface Message {
  langCode: string;
  text: string;
}

export interface UserData {
  preferedLocale: string;
}

export const getAtaNotificationTypeName = (
  ataNotificationType: AtaNotificationType 
): string => {
  switch (ataNotificationType) {
    case AtaNotificationType.ALARM:
      return t`Alarm`;
    case AtaNotificationType.FAILURE:
      return t`Failure`;
    case AtaNotificationType.NONE:
      return t`Never`;
    default:
      return t`Alarm`;
  }
};

import React, { useState, useEffect } from "react";
import * as serviceWorker from "../../serviceWorker";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { AlertTitle, AlertProps } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  })
);

interface Props {
  children: React.ReactElement;
}

const WithServiceWorker: React.FC<Props> = (props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [updatedState, setUpdatedState] = useState(false);
  const [regRegisteredState, setRegisteredState] = useState<ServiceWorkerRegistration | null>(null);

  useEffect(() => {
    serviceWorker.register({
      onUpdate: (registration: ServiceWorkerRegistration) => {
        console.log("Service worker on update method");
        setUpdatedState(true);
        setRegisteredState(registration);
        setOpen(true);
      },
    });
  }, []);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleUpdateServiceWorker = () => {
    if (!regRegisteredState || regRegisteredState.waiting === null) {
      return;
    }

    const registrationWaiting = regRegisteredState.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });

      registrationWaiting.addEventListener("statechange", ({ target }) => {
        const state = target ? (target as ServiceWorker).state : "not activated";
        if (state === "activated") {
          window.location.reload();
        }
      });
    }
  };

  return (
    <>
      <div className={classes.root}>
        {updatedState && (
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              severity="info"
              variant="filled"
              action={
                <Button color="inherit" size="small" onClick={handleUpdateServiceWorker}>
                  Update
                </Button>
              }
            >
              <AlertTitle>Info</AlertTitle>
              An updated version of this app is available!
            </Alert>
          </Snackbar>
        )}
      </div>
      <div>{props.children}</div>
    </>
  );
};

export default WithServiceWorker;

import React, { useState, useEffect } from "react";
import { AtaNotificationType, UpdateAtaConfigInput } from "../../../API";
import useGlobal from "../../../store";
import { Device, getAtaNotificationTypeName } from "../../../store/extendedApiTypes";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import MaterialTableIcons from "../../hoc/MaterialTableIcons";
import { Hidden, Grid, Paper } from "@material-ui/core";

import { t } from "@lingui/macro";
import { I18n as i18nCore } from "@lingui/core";
import { getMaterialUiTableLocalizationDefs } from "../../auth/i18nUtils";

const useStyles = makeStyles(() =>
  createStyles({
    detail: {
      marginTop: 5,
      marginLeft: 70,
      marginBottom: 10,
    },
  })
);

interface IDeviceSettingsSectionProps {
  i18n: i18nCore;
}

const DeviceSettingsSection: React.FunctionComponent<IDeviceSettingsSectionProps> = ({ i18n }) => {
  const classes = useStyles();
  const [{ devices }, { updateAtaConfig }] = useGlobal();

  const [deviceEntries, setDeviceEntries] = useState<Device[]>([]);
  useEffect(() => {
    setDeviceEntries([...devices]);
  }, [devices]);

  const onRowUpdate = async (newEntry: Device, oldEntry?: Device | undefined): Promise<any> =>
    new Promise((resolve) => {
      setTimeout(() => {
        if (oldEntry) {
          const newEntries = [...deviceEntries];
          const index = newEntries.indexOf(oldEntry);
          newEntries[index] = newEntry;
          setDeviceEntries(newEntries);
          writeTableEntryToCloud(newEntry);
        }

        resolve("");
      }, 1000);
    });

  async function writeTableEntryToCloud(entry: Device) {
    const updateInput: UpdateAtaConfigInput = {
      deviceType: entry.type,
      deviceId: entry.id,
      ataNoteType: entry.ataNoteType,
    };
    updateAtaConfig(updateInput);
  }

  return (
    <>
      {deviceEntries && (
        <>
          <Hidden xsDown>
            <Grid item xs={12}>
              <MaterialTable
                title={t`Alarm Notifications`}
                columns={[
                  { title: t`Engine`, field: "engine", editable: "never" },
                  { title: t`Serial number`, field: "id", editable: "never" },
                  { title: t`Plant`, field: "plant", editable: "never" },
                  { title: t`Application`, field: "appName", editable: "never" },
                  {
                    title: t`Notify`,
                    field: "ataNoteType",
                    lookup: getNoficationLookup(i18n),
                  },
                ]}
                data={deviceEntries}
                icons={MaterialTableIcons}
                editable={{
                  onRowUpdate: onRowUpdate,
                }}
                options={{
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  headerStyle: {
                    fontWeight: "bold",
                  },
                }}
                localization={getMaterialUiTableLocalizationDefs(i18n)}
              />
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item xs={12}>
              <MaterialTable
                title={t`Alarm Notifications`}
                columns={[
                  { title: t`Engine`, field: "engine", editable: "never" },
                  {
                    title: t`Notify`,
                    field: "ataNoteType",
                    lookup: getNoficationLookup(i18n),
                  },
                ]}
                data={deviceEntries}
                icons={MaterialTableIcons}
                editable={{
                  onRowUpdate: onRowUpdate,
                }}
                detailPanel={(rowData) => {
                  return (
                    <Hidden smUp>
                      <Paper elevation={0} className={classes.detail}>
                        <p>{`${t`Serial number`}: ${(rowData as Device).id}`}</p>
                        <p>{`${t`Plant`}: ${(rowData as Device).plant}`}</p>
                        <p>{`${t`Time zone`}: ${(rowData as Device).timeZone}`}</p>
                        <p>{`${t`Application`}: ${(rowData as Device).appName}`}</p>
                      </Paper>
                    </Hidden>
                  );
                }}
                onRowClick={(event, rowData, togglePanel) => {
                  if (togglePanel) togglePanel();
                }}
                options={{
                  actionsColumnIndex: -1,
                  search: false,
                }}
                localization={getMaterialUiTableLocalizationDefs(i18n)}
              />
            </Grid>
          </Hidden>
        </>
      )}
    </>
  );
};

const getNoficationLookup = (i18n: i18nCore) => ({
  [AtaNotificationType.ALARM]: getAtaNotificationTypeName(AtaNotificationType.ALARM),
  [AtaNotificationType.FAILURE]: getAtaNotificationTypeName(AtaNotificationType.FAILURE),
  [AtaNotificationType.NONE]: getAtaNotificationTypeName(AtaNotificationType.NONE),
});

export default DeviceSettingsSection;

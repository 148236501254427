import { I18n } from "aws-amplify";
import germanTranslations from "./amplifyAuthComponentTranslations.de"

I18n.putVocabulariesForLanguage("de", germanTranslations);

const setLanguageForAmplifyAuthComponent = (language: string): void => {
    I18n.setLanguage(language);
}

export default setLanguageForAmplifyAuthComponent;


import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, Typography, MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    label: {
      padding: theme.spacing(2),
    },
    select: {
      padding: theme.spacing(1.5),
      fontSize: 14,
    },
  })
);

interface ISelectionBoxProps {
  label: string;
  value: string;
  options: { value: string; text: string }[];
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  styles?: Record<string, string>;
}

const SelectionBox: React.FunctionComponent<ISelectionBoxProps> = ({
  label,
  value,
  options,
  disabled,
  onChange,
  styles,
}) => {
  const classes = useStyles();

  return (
    <div className={styles && styles.root ? styles.root : classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Typography variant="body2" className={classes.label} noWrap>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Select
            id="notification-locale-select"
            value={value}
            onChange={onChange}
            disabled={disabled}
            disableUnderline
            className={classes.select}
          >
            {options.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.text}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectionBox;

import React from "react";
import { OperationMode } from "../../deviceCard/openECSDeviceCard/useOpenECSDeviceCardValues";
import ImageWithCounterRenderer from "../imageWithCounterRenderer";
import { deviceToLinkElement } from "../../navigation/deviceNavigationUtils";
import { Device } from "../../../store/extendedApiTypes";
import { Link } from "react-router-dom";

import alarmIcon from "../../../images/warning_modavat_24_enabled.svg";
import failureIcon from "../../../images/failure_modavat_24_enabled.svg";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ManualIcon from "@material-ui/icons/PanToolOutlined";
import AutoIcon from "@material-ui/icons/DataUsageOutlined";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerContainer: {
      width: "130px",
      height: "40px",
      margin: "10px 15px 15px 10px",
    },
    icon: {
      color: theme.palette.secondary.main,
    },
    operationModeIconContainer: {
      padding: "5px 0px 5px 1px",
    },
  })
);

const useFailureStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {},
    iconText: {
      //color: "#ef0000" // color of the lightning icon
    },
  })
);

const useAlarmStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {},
    iconText: {
      //color: "#ed8c00" // color of the alarm icon
    },
  })
);

interface IAlarmEventsRendererProps {
  device: Device;
  failuresAvailable: boolean | undefined;
  alarmsAvailable: boolean | undefined;
  operationMode: OperationMode | undefined;
}

const AlarmsAndOperationModeRenderer: React.FunctionComponent<IAlarmEventsRendererProps> = ({
  device,
  failuresAvailable,
  alarmsAvailable,
  operationMode,
}) => {
  const classes = useStyles();
  const failureClasses = useFailureStyles();
  const alarmClasses = useAlarmStyles();

  const deviceAlarmsLink = `/fleet/device/${deviceToLinkElement(device)}/alarms`;

  let operationModeIcon = null;
  if (operationMode && operationMode === OperationMode.MANUAL) {
    operationModeIcon = <ManualIcon className={classes.icon} />;
  } else if (operationMode === OperationMode.AUTO) {
    operationModeIcon = <AutoIcon className={classes.icon} />;
  }

  return (
    <div className={classes.outerContainer}>
      <Grid container spacing={2}>
        {failuresAvailable && (
          <Grid item xs={4} component={Link} to={deviceAlarmsLink}>
            <ImageWithCounterRenderer iconSource={failureIcon} styles={failureClasses} />
          </Grid>
        )}
        {!failuresAvailable && (
          <Grid item xs={4}>
            {/* Just as placeholder without link */}
          </Grid>
        )}
        {alarmsAvailable && (
          <Grid item xs={4} component={Link} to={deviceAlarmsLink}>
            <ImageWithCounterRenderer iconSource={alarmIcon} styles={alarmClasses} />
          </Grid>
        )}
        {!alarmsAvailable && (
          <Grid item xs={4}>
            {/* Just as placeholder without link */}
          </Grid>
        )}
        <Grid item xs={4}>
          <div className={classes.operationModeIconContainer}>{operationModeIcon}</div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AlarmsAndOperationModeRenderer;

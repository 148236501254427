import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useDeviceCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //minWidth: 275
    },
    pos: {
      marginBottom: 12
    },
    title: {
      marginBottom: 12
    }
  })
);

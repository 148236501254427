import { useDatapointsForDisplay } from "./../../../store/datapoints/useDatapointsForDisplay";
import { Device } from "../../../store/extendedApiTypes";
import { getMessageTextOrUndefined } from "../../../store/extendedApiUtils";
import LanguageContext from "../../hoc/LanguageContextProvider";
import { useContext } from "react";

export enum OperationMode {
  MANUAL = "Manual",
  AUTO = "Auto",
  UNKNOWN = "UNKNOWN",
}

enum TEMDatapointId {
  FailuresAvailable = "TEM-23",
  AlarmsAvailable = "TEM-24",
  OperationMode = "TEM-25",
  HoursOfLoadRun = "TEM-6",
  PowerInPercent = "TEM-2",
  NumberOfStarts = "TEM-7",
  HoursUntilNextMaintenance = "TEM-27",
  OperationState = "TEM-28"
}

export interface TEMDeviceCardValues {
  PowerInPercent: number | undefined;
  OperationMode: OperationMode | undefined;
  HoursOfLoadRun: number | undefined;
  NumberOfStarts: number | undefined;
  HoursUntilNextMaintenance: number | undefined;
  FailuresAvailable: boolean | undefined;
  AlarmsAvailable: boolean | undefined;
  ValuesTimestamp: string | undefined;
  OperationState: string | undefined;
}

export interface UseTEMDeviceCardValuesState {
  loading: boolean;
  error: any;
  deviceCardValues: TEMDeviceCardValues | null;
}

export const useTEMDeviceCardValues = (device: Device): UseTEMDeviceCardValuesState => {
  const { currentLanguage } = useContext(LanguageContext);

  const {
    loading,
    error,
    enrichedDatapoints,
    analogDatapointsById,
    discreteDatapointsById,
  } = useDatapointsForDisplay(device);

  if (!enrichedDatapoints) {
    return { loading, error, deviceCardValues: null };
  }

  const determineOperationMode = (): OperationMode => {
    const discreteValue = getMessageTextOrUndefined(
      discreteDatapointsById.get(TEMDatapointId.OperationMode)?.discreteValue,
      currentLanguage
    );

    if (!discreteValue) {
      return OperationMode.UNKNOWN;
    }

    return discreteValue === "remote" || discreteValue === "Fern"
      ? OperationMode.AUTO
      : OperationMode.MANUAL;
  };

  const deviceCardValues: TEMDeviceCardValues = {
    PowerInPercent: analogDatapointsById.get(TEMDatapointId.PowerInPercent)?.analogValue,
    OperationMode: determineOperationMode(),
    OperationState: getMessageTextOrUndefined(
      discreteDatapointsById.get(TEMDatapointId.OperationState)?.discreteValue,
      currentLanguage
    ),
    HoursOfLoadRun: analogDatapointsById.get(TEMDatapointId.HoursOfLoadRun)?.analogValue,
    NumberOfStarts: analogDatapointsById.get(TEMDatapointId.NumberOfStarts)?.analogValue,
    HoursUntilNextMaintenance: analogDatapointsById.get(TEMDatapointId.HoursUntilNextMaintenance)
      ?.analogValue,
    FailuresAvailable:
      analogDatapointsById.get(TEMDatapointId.FailuresAvailable)?.analogValue === 0,
    AlarmsAvailable: analogDatapointsById.get(TEMDatapointId.AlarmsAvailable)?.analogValue === 0,
    ValuesTimestamp: enrichedDatapoints.timestamp,
  };

  return { loading, error, deviceCardValues };
};

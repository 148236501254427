import { t } from "@lingui/macro";

//NOTE: this should be moved to the database. We should not openly expose product IDs

const EDI_TAG_TRANSLATIONS = new Map<string, string>([
  ["Operation", t`Operation`],
  ["EngineData", t`EngineData`],
  ["GeneratorData", t`GeneratorData`],
  ["Oil", t`Oil`],
  ["CoolingWater", t`CoolingWater`],
  ["ReturnTemperature", t`ReturnTemperature`],
  ["GasAndAir", t`GasAndAir`], //TODO better name!
  ["GasMix", t`GasMix`], //TODO better name!
  ["ExhaustGas", t`ExhaustGas`],
  ["Cylinders", t`Cylinders`],
  ["#UNTAGGED", t`Others`],
]);

const TEM_TAG_TRANSLATIONS = new Map<string, string>([
  ["Operation", t`Operation`],
  ["EngineData", t`EngineData`],
  ["Oil", t`Oil`],
  ["CoolingWater", t`CoolingWater`],
  ["HeatingWater", t`HeatingWater`],
  ["GasMix", t`GasMix`], //TODO better name!
  ["ExhaustGas", t`ExhaustGas`],
  ["Cylinders", t`Cylinders`],
  ["#UNTAGGED", t`Others`],
]);

const OPTIMIZER_TAG_TRANSLATIONS = new Map<string, string>([
  ["KPIs_Operation", t`KPIs_Operation`],
  ["KPIs_Components", t`KPIs_Components`],
  ["KPIs_Malos", t`KPIs_Malos`],
  ["#UNTAGGED", t`Others`],
]);

// hard-coded order of the sections, as there is no natural way to sort them. Contains tag-scopes (parts of a tag).
const EDI_SECTION_ORDER = [
  "Operation",
  "EngineData",
  "GeneratorData",
  "Oil",
  "CoolingWater",
  "ReturnTemperature",
  "GasAndAir",
  "GasMix",
  "ExhaustGas",
  "Cylinders",
  "#UNTAGGED",
];

const TEM_SECTION_ORDER = [
  "Operation",
  "EngineData",
  "Oil",
  "CoolingWater",
  "HeatingWater",
  "GasMix",
  "ExhaustGas",
  "Cylinders",
  "#UNTAGGED",
];

const OPTIMIZER_SECTION_ORDER = ["KPIs_Operation", "KPIs_Components", "KPIs_Malos", "#UNTAGGED"];

function getTagTranslationsForProduct(deviceType: string, appName: string): Map<string, string> {
  switch (deviceType) {
    case "OPENECS": {
      switch (appName) {
        case "E2CON-HO":
        case "EDI":
          return EDI_TAG_TRANSLATIONS;
        // add additonall apps here
        default:
          break;
      }
      break;
    }
    case "SEVA":
    case "TEM": {
      return TEM_TAG_TRANSLATIONS;
    }
    case "SE2OPTIMIZER": {
      return OPTIMIZER_TAG_TRANSLATIONS;
    }
    // add additional product types here
    default:
      break;
  }
  return new Map<string, string>([["#UNTAGGED", t`Others`]]);
}

const getSectionOrderForProduct = (deviceType: string, appName: string): string[] => {
  switch (deviceType) {
    case "OPENECS": {
      switch (appName) {
        case "E2CON-HO":
        case "EDI":
          return EDI_SECTION_ORDER;
        // add additonall apps here
        default:
          break;
      }
      break;
    }
    case "SEVA":
    case "TEM": {
      return TEM_SECTION_ORDER;
    }
    case "SE2OPTIMIZER": {
      return OPTIMIZER_SECTION_ORDER;
    }
    // add additional product types here
    default:
      break;
  }

  return ["#UNTAGGED"];
};

export { getTagTranslationsForProduct, getSectionOrderForProduct };

import * as React from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumb: {
      marginTop: -10,
    },
    menuButton: {
      color: theme.palette.secondary.dark,
      textAlign: 'left'
    }
  })
);

interface ISettingsBreadcrumbsProps {
}

const DeviceBreadcrumbs: React.FunctionComponent<ISettingsBreadcrumbsProps> = () => {
  const classes = useStyles();
  
  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}> 
        <Button
          size="small"
          color="inherit"
          variant="text"
          component={Link}
          to="/settings"
          className={classes.menuButton}
        >
          Settings
        </Button>
        <Switch>
          <Route
            path="/settings/alarms"
            component={() => (
              <Button size="small" color="inherit" className={classes.menuButton}>
                Alarms
              </Button>
            )}
          />
          <Route
            path="/settings/general"
            component={() => (
              <Button size="small" color="inherit" className={classes.menuButton}>
                General
              </Button>
            )}
          />
          <Redirect from="/settings" to="/settings/alarms" />
        </Switch>
      </Breadcrumbs>
  );
};

export default DeviceBreadcrumbs;

import React, { FunctionComponent, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from "@material-ui/icons/Menu";
import NavigationDrawer from "../navigationDrawer/NavigationDrawer";
import navigationStructure from "../mainNavigationStructure";
import NavigationItem from "../navigationItem";
import CssBaseline from "@material-ui/core/CssBaseline";
import useStyles from "./TopAppBar.styles";
import avatLogo from "../../../images/AVAT_Logo_Standard_4C_positiv.svg";
import useLocationInMainNavigation from "../useLocationInMainNavigation";
import InstallButton from "../../installButton";
import { Trans } from "@lingui/macro";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import LanguageContext from "../../hoc/LanguageContextProvider";
import setLanguageForAmplifyAuthComponent from "../../auth/amplifyAuthComponentConfig";
import { LOCALES_WITH_DESCRIPTION } from "../../auth/i18nUtils";

interface Props {}

const TopAppBar: FunctionComponent<Props> = (props) => {
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);

  const classes = useStyles();
  const location = useLocation();
  const locationInMainNavigation = useLocationInMainNavigation(location);

  function resolveStyleClass(
    theLocationinMainNavigation: NavigationItem | null,
    itemUrl: string
  ): string {
    if (theLocationinMainNavigation === null) {
      return "";
    }
    if (theLocationinMainNavigation.url === itemUrl) {
      return classes["urlMatch"]; // see style definition
    }
    return "";
  }

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const signOutButtonDef = navigationStructure.items.find((item) => item.desc === "signOutItem");

  if (!signOutButtonDef) {
    throw new Error("Missing definition for sign out button");
  }

  const menuButtons = navigationStructure.items
    .filter((item) => item.desc !== "signOutItem")
    .map((item: NavigationItem) => (
      <Button
        color="inherit"
        component={Link}
        to={item.url}
        className={`${classes.menuButton} ${resolveStyleClass(locationInMainNavigation, item.url)}`}
        key={item.key}
      >
        <Trans id={item.label} />
      </Button>
    ));

  function handleLocaleChange(event: React.ChangeEvent<{ value: unknown }>) {
    event.preventDefault();
    const locale = event.target.value as string;
    setCurrentLanguage(locale);
    setLanguageForAmplifyAuthComponent(locale);
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="sticky">
        <Toolbar className={classes.appBar}>
          <Hidden xsDown>
            <Link to="/">
              <img src={avatLogo} height={60} alt="AVAT Logo" />
            </Link>
            <div className={classes.grow} />
          </Hidden>
          <Hidden smUp>
            <IconButton
              edge="start"
              className={`${classes.menuButton} burgerMenuButton`}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden xsDown>{menuButtons}</Hidden>
          <div className={classes.grow} />
          <Select
            labelId="locale-select-label"
            id="locale-select"
            value={currentLanguage}
            onChange={handleLocaleChange}
          >
            {LOCALES_WITH_DESCRIPTION.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.text}
              </MenuItem>
            ))}
          </Select>
          <InstallButton />
          <Hidden xsDown>
            <Button
              id="logout-button"
              color="inherit"
              component={Link}
              to={signOutButtonDef.url}
              className={`${classes.menuButton} ${resolveStyleClass(
                locationInMainNavigation,
                signOutButtonDef.url
              )}`}
              key={signOutButtonDef.key}
            >
              <Trans id={signOutButtonDef.label} />
            </Button>
          </Hidden>
        </Toolbar>
      </AppBar>
      <NavigationDrawer toggle={toggleDrawer} width={300} isOpen={drawerOpen} />
    </React.Fragment>
  );
};

export default TopAppBar;

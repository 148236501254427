import React from "react";
import { Link } from "react-router-dom";
import { deviceToLinkElement } from "../../navigation/deviceNavigationUtils";
import { useDeviceCardStyles } from "../DeviceCard.styles";
import { IDeviceCardProps } from "../DeviceCard";
import engine from "../../../images/AVAT-SE2OPTIMIZER-alpha_256x256_web.png";

import {
  useStyles,
  // useCardHeaderStyles,
  useCardBodyAndActionStyles,
} from "./SEOptimizerDeviceCard.styles";

import { getFormattedDateTimeLabel } from "../deviceCardUtils";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Grid, CardHeader, Avatar, makeStyles, Theme } from "@material-ui/core";
import OperationhoursIcon from "@material-ui/icons/UpdateOutlined";
import EngineStartsIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import NextMaintenanceIcon from "@material-ui/icons/BuildOutlined";
import PowerIcon from "@material-ui/icons/SpeedRounded";
import OperationStateIcon from "@material-ui/icons/InfoOutlined";
import Hidden from "@material-ui/core/Hidden";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import LabeledIconRenderer from "../../dataPointRenderers/labeledIconRenderer";

const useCardHeaderStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  title: {
    fontSize: "1.3em",
    fontWeight: "bold",
  },
}));

const SE2OptimizerDeviceCard: React.FunctionComponent<IDeviceCardProps> = ({ device }) => {
  const classes = useStyles();
  const { i18n } = useLingui();

  const commonCardStyles = useDeviceCardStyles();
  const cardHeaderStyles = useCardHeaderStyles();
  const cardBodyAndActionStyles = useCardBodyAndActionStyles();

  return (
    <Card className={`${commonCardStyles.root} seOptimizerDeviceCard`}>
      <CardHeader
        avatar={<Avatar aria-label="seOptimizer Engine" className={classes.avatar} src={engine} />}
        title={"SE²Optimizer Demo Device"}
        subheader={"AVAT Tübingen"}
        classes={cardHeaderStyles}
      />

      <CardContent classes={cardBodyAndActionStyles}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LabeledIconRenderer icon={PowerIcon} label={"97,32 %"} />
          </Grid>
          <Grid item xs={6}>
            <LabeledIconRenderer icon={OperationStateIcon} label={"Online"} />
          </Grid>
        </Grid>
        <Hidden xsDown>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LabeledIconRenderer icon={PowerIcon} label={"4752,3 kW"} />
            </Grid>
            <Grid item xs={6}>
              <LabeledIconRenderer icon={OperationhoursIcon} label={"61 h"} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LabeledIconRenderer icon={EngineStartsIcon} label={"4"} />
            </Grid>
            <Grid item xs={6}>
              <LabeledIconRenderer icon={NextMaintenanceIcon} label={"62 h"} />
            </Grid>
          </Grid>
        </Hidden>
      </CardContent>

      <CardActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              className={classes.actionButton}
              size="small"
              component={Link}
              to={`/fleet/device/${deviceToLinkElement(device)}`}
            >
              <Trans>Show details</Trans>
            </Button>
          </Grid>
          <Grid item xs={6}>
            {getFormattedDateTimeLabel(classes.lastUpdatedTime, "2020-12-14T13:30:36.156Z", i18n)}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default SE2OptimizerDeviceCard;

import * as React from "react";
import Card from "@material-ui/core/Card";
import { Device } from "../../store/extendedApiTypes";
import { DeviceType } from "../../API";
import SE2OptimizerDeviceCard from "./seOptimizerDeviceCard";
import OpenECSDeviceCard from "./openECSDeviceCard";
import TEMDeviceCard from "./temDeviceCard";
import SEVADeviceCard from './sevaDeviceCard'

export interface IDeviceCardProps {
  device: Device;
}

const DeviceCard: React.FunctionComponent<IDeviceCardProps> = ({ device }) => {
  switch (device.type) {
    case DeviceType.SE2OPTIMIZER:
      return <SE2OptimizerDeviceCard device={device} />;
    case DeviceType.OPENECS:
      return <OpenECSDeviceCard device={device} />;
    case DeviceType.TEM:
      return <TEMDeviceCard device={device} />;
    case DeviceType.SEVA:
      return <SEVADeviceCard device={device} />;
    case DeviceType.TESTDUMMY:
      return <Card>{/*Device type for backend tests*/}</Card>;
  }
};

export default DeviceCard;
